import React, { useEffect, useRef, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { FaChevronRight, FaChevronLeft, FaCrown } from 'react-icons/fa'
import { NftEntity } from '../../hooks/useApi'
import { ADA_SYMBOL, intToString } from '../../util/format'
import { MoonLoader } from 'react-spinners'

interface Props {
  nfts: NftEntity[]
  index: number
  onClose: () => void
}

export const NftDetailsModal: React.FC<Props> = ({ nfts, index, onClose }) => {
  const [showSpinner, setShowSpinner] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(index)
  const [nft, setNft] = useState(nfts[currentIndex])
  const [imageUrl, setImageUrl] = useState(nft.image_url)

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!divRef.current) return

    divRef.current.focus()
  }, [])

  useEffect(() => {
    setImageUrl(nfts[currentIndex].image_url)
  }, [currentIndex])

  const handleKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      onClose()
    } else if (e.key === 'ArrowLeft') {
      showPreviousNft()
    } else if (e.key === 'ArrowRight') {
      showNextNft()
    }
  }

  const showPreviousNft = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
      setNft(nfts[currentIndex - 1])
    } else {
      setCurrentIndex(nfts.length - 1)
      setNft(nfts[nfts.length - 1])
    }

    setShowSpinner(true)
  }

  const showNextNft = () => {
    if (currentIndex < nfts.length - 1) {
      setCurrentIndex(currentIndex + 1)
      setNft(nfts[currentIndex + 1])
    } else {
      setCurrentIndex(0)
      setNft(nfts[0])
    }

    setShowSpinner(true)
  }

  return (
    <>
      <div
        ref={divRef}
        className="fixed z-50 w-full h-full transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-95 top-1/2 left-1/2"
        onKeyDown={handleKeypress}
        tabIndex={-1}>
        <p
          className="absolute text-sm font-bold text-white top-3 hover:cursor-pointer right-4"
          onClick={() => onClose()}>
          <IoClose size={25} />
        </p>

        <p
          className="absolute -translate-y-1/2 top-1/2 left-4 hover:cursor-pointer"
          onClick={showPreviousNft}>
          <FaChevronLeft color={'white'} size={30} />
        </p>

        <p
          className="absolute -translate-y-1/2 top-1/2 right-4 hover:cursor-pointer"
          onClick={showNextNft}>
          <FaChevronRight color={'white'} size={30} />
        </p>

        <div className="flex flex-col justify-center w-full h-full px-20 py-20">
          <div className="relative flex flex-col w-full h-full gap-10 p-10 overflow-y-scroll rounded-lg lg:justify-center lg:flex-row bg-neutral-900">
            <img
              src="/images/logo_small.png"
              className="absolute hidden w-10 select-none top-5 right-5 lg:flex"
            />
            <div className="flex justify-center w-full lg:w-1/2 lg:h-full sm:h-3/4">
              {showSpinner && (
                <MoonLoader
                  color={'#ffffff'}
                  loading={true}
                  size={50}
                  className="self-center"
                />
              )}

              <img
                src={imageUrl}
                onLoad={() => setShowSpinner(false)}
                className={`object-contain object-top ${
                  showSpinner ? 'w-0' : 'w-full'
                } rounded-lg select-none`}
              />
            </div>

            <div className="flex flex-col lg:w-1/2">
              <p className="text-lg font-bold select-none text-neutral-600">
                {nft.project_name}
              </p>
              <p className="mt-2 text-4xl font-bold text-white select-none">
                {nft.display_name}
              </p>

              <div className="flex flex-row flex-wrap w-full gap-3 mt-2">
                <p className="self-center text-xl font-bold text-white">
                  {ADA_SYMBOL} {nft.estimated_value || 'n/a'}{' '}
                  <span className="text-xs text-neutral-400">
                    Estimated Value
                  </span>
                </p>
                <p className="self-center text-white">|</p>
                <p className="self-center text-xl font-bold text-white">
                  {ADA_SYMBOL} {nft.floor_value || 'n/a'}{' '}
                  <span className="text-xs text-neutral-400">Floor Value</span>
                </p>
                <p className="self-center text-white">|</p>

                <p className="self-center text-xl font-bold text-white">
                  {ADA_SYMBOL}{' '}
                  {(nft.instant_value && nft.instant_value) || 'n/a'}{' '}
                  <span className="text-xs text-neutral-400">
                    Instant Value
                  </span>
                </p>
              </div>

              <a
                href={nft.jpg_link}
                target="_blank"
                rel="noreferrer"
                className="w-full py-3 mt-8 font-semibold text-center text-white border border-yellow-500 hover:bg-neutral-800 rounded-xl">
                View on JPG.store
              </a>

              <p className="mt-8 text-2xl font-bold text-white">Traits</p>

              <div className="grid grid-cols-2 gap-4 p-6 mt-4 overflow-y-auto rounded-lg sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 bg-stone-800">
                {nft.traits
                  .sort((a, b) => b.current_price - a.current_price)
                  .map((trait, index) => (
                    <div
                      className="px-4 py-3 border rounded-lg border-neutral-700 bg-neutral-900"
                      key={index}>
                      <div className="flex flex-row items-center justify-between">
                        <p className="self-center text-xs font-bold uppercase truncate text-neutral-400">
                          {trait.name}
                        </p>
                        {index === 0 && (
                          <FaCrown color={'#eab308'} className="self-center" />
                        )}
                      </div>
                      <p className="mt-1 text-sm font-bold truncate text-neutral-200">
                        {trait.value}
                      </p>
                      <p className="mt-1 text-lg font-bold text-white truncate">
                        {ADA_SYMBOL} {trait.current_price || 'n/a'}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
