import React, { useEffect, useState } from 'react'
import { RoutePaths } from '../../route-paths'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { ADA_SYMBOL } from '../../util/format'
import { BounceLoader } from 'react-spinners'
import useApi from '../../hooks/useApi'

const POKI_COLLECTION_ID = '937'

export const PricingBoxes = () => {
  const [floor, setFloor] = useState(0)

  const { getCollection } = useApi()

  useEffect(() => {
    const fetchFloor = async () => {
      try {
        const result = await getCollection(POKI_COLLECTION_ID, '24h')
        if (result.collection) {
          setFloor(result.collection.stats.floor)
        } else {
          console.log('An unknown error occured')
        }
      } catch (error) {
        console.log('An unknown error occured')
      }
    }

    fetchFloor()
  }, [])
  return (
    <>
      <div className="flex flex-col w-full gap-8 mt-10 md:flex-row">
        <div className="flex flex-col w-full px-10 py-10 border rounded-xl md:w-1/2 bg-neutral-800 border-neutral-700">
          <p className="mt-2 text-5xl font-bold text-neutral-200">Basic</p>

          <p className="mt-8 text-sm text-neutral-400 text-wide">
            Poki Basic is free of charge for everyone. While being a powerful
            addition to the toolbox of every Cardano investor, it comes with a
            limited set of features.
          </p>

          <div className="flex flex-col gap-6 p-5 mt-12 rounded-lg bg-neutral-700">
            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Valuation of your Cardano NFTs
                </p>
                <p className="text-xs text-neutral-400">
                  Get the combined value of your assets. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Token / Coin Tracking
                </p>
                <p className="text-xs text-neutral-400">
                  Track your coins like $meld and $clay. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Portfolio Analytics
                </p>
                <p className="text-xs text-neutral-400">
                  Your biggest gainers / decliners at one glance. Status:
                  Available.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full px-10 py-10 border rounded-xl md:w-1/2 bg-neutral-800 border-neutral-700">
          <p className="mt-2 text-5xl font-bold text-purple-600">Pro</p>

          <p className="mt-8 text-sm text-neutral-400 text-wide">
            Poki Pro is currently only unlockable through holding a{' '}
            <a
              className="font-bold text-purple-600"
              href={RoutePaths.LifetimePass}>
              Poki Lifetime Pass.{' '}
            </a>
            It comes with a lot of additional features and is a must-have for
            every serious Cardano investor.
          </p>

          <div className="flex flex-col gap-6 p-5 mt-12 rounded-lg bg-neutral-700">
            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Add Unlimited Wallets
                </p>
                <p className="text-xs text-neutral-400">
                  Unlock the 1-Wallet-Limit. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Mobile App Push Notifications
                </p>
                <p className="text-xs text-neutral-400">
                  Get automatically notified about your Wallet Transactions,
                  Price Alerts, Whale Alerts, etc. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  AI-Driven NFT Valuation
                </p>
                <p className="text-xs text-neutral-400">
                  Stay ahead in the NFT game with our cutting-edge machine
                  learning model. Status: available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Discord Transaction Notifications
                </p>
                <p className="text-xs text-neutral-400">
                  Get automatically notified about your Wallet Transactions
                  through our Poki Discord Bot.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Poki Custom Node
                </p>
                <p className="text-xs text-neutral-400">
                  Get exclusive access to the Poki Custom Node to speed up your
                  transactions. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  Access Portfolio History
                </p>
                <p className="text-xs text-neutral-400">
                  See how your Portfolio performed over time. Status: Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  NFTs listed on JPG.store
                </p>
                <p className="text-xs text-neutral-400">
                  Automatically track your JPG.store listings. Status:
                  Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  NFTs staked with Mutant Labs
                </p>
                <p className="text-xs text-neutral-400">
                  Automatically track your NFTs staked with Mutant Labs. Status:
                  Available.
                </p>
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="flex self-center justify-center w-10 h-10">
                <IoCheckmarkSharp
                  color="#16a34a"
                  className="self-center"
                  size={30}
                />
              </div>
              <div className="flex flex-col self-center w-full">
                <p className="text-sm font-semibold tracking-wide text-neutral-200">
                  ADA loaned out via Lending Pond
                </p>
                <p className="text-xs text-neutral-400">
                  Automatically track your ADA loaned out via Lending Pond.
                  Status: Available.
                </p>
              </div>
            </div>
          </div>

          <a
            href="https://www.jpg.store/collection/poki-lifetime-pass"
            target="_blank"
            rel="noreferrer"
            className="py-4 mt-6 text-sm font-semibold text-center bg-yellow-400 rounded-lg hover:bg-yellow-300 px-28">
            Buy on JPG.store
          </a>
        </div>
      </div>
    </>
  )
}
