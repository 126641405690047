import React, { useEffect, useRef } from 'react'
import { createChart, ISeriesApi } from 'lightweight-charts'
import { ADA_SYMBOL, numberOfLeadingZeroes } from '../../util/format'

interface CandlestickData {
  time: string
  open: number
  high: number
  low: number
  close: number
}

interface CandlestickChartProps {
  candlestickSeries: CandlestickData[]
  priceFormatter?: (price: number) => string
}

const CandlestickChart: React.FC<CandlestickChartProps> = ({
  candlestickSeries,
  priceFormatter,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<ISeriesApi<'Candlestick'> | null>(null)

  const decimalPlaces = (price: number) => {
    const leadingZeroesCount = numberOfLeadingZeroes(price)

    if (leadingZeroesCount === 0 && price < 1) return 3
    if (price >= 1) return 2

    return leadingZeroesCount + 3
  }

  useEffect(() => {
    if (containerRef.current) {
      const chart = createChart(containerRef.current, {
        layout: {
          background: { color: '#262626' },
          textColor: '#C3BCDB',
        },
        grid: {
          vertLines: { color: '#444' },
          horzLines: { color: '#444' },
        },
      })

      chart.applyOptions({
        layout: {
          fontFamily: "'Roboto', sans-serif",
          fontSize: 12,
        },
      })

      if (priceFormatter) {
        chart.applyOptions({
          localization: {
            priceFormatter: priceFormatter,
          },
        })
      } else {
        chart.applyOptions({
          localization: {
            priceFormatter: (price: number) =>
              `${ADA_SYMBOL} ${price.toFixed(decimalPlaces(price))}`,
          },
        })
      }

      chart.timeScale().applyOptions({
        borderColor: '#71649C',
        barSpacing: 10,
      })

      chart.applyOptions({
        crosshair: {
          mode: 0, // 0 corresponds to LightweightCharts.CrosshairMode.Normal
          vertLine: {
            width: 4,
            color: '#C3BCDB44',
            style: 0, // 0 corresponds to LightweightCharts.LineStyle.Solid
            labelBackgroundColor: '#9B7DFF',
          },
          horzLine: {
            color: '#9B7DFF',
            labelBackgroundColor: '#9B7DFF',
          },
        },
      })

      const candlestickSeriesApi = chart.addCandlestickSeries()
      candlestickSeriesApi.setData(candlestickSeries)

      chartRef.current = candlestickSeriesApi

      return () => {
        chart.remove()
      }
    }
  }, [candlestickSeries])

  return <div ref={containerRef} className="w-full h-96 rounded-xl" />
}

export default CandlestickChart
