import React from 'react'
import { ADA_SYMBOL } from '../../util/format'

export const Faq: React.FC = () => {
  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 xl:w-2/3 3xl:w-1/2">
          <p className="text-6xl font-bold text-neutral-200">FAQ</p>

          <p className="mt-8 text-2xl font-semibold text-neutral-200">
            Push Notifications don&apos;t work on my Poki App
          </p>
          <ul className="mt-4 ml-3 space-y-1 text-base list-decimal list-inside text-neutral-200">
            <li>
              Install the latest Poki Mobile App Version from the App Store.
            </li>
            <li>Log out of the App.</li>
            <li>
              Log into the Mobile App with your Pro Account (as described on
              your{' '}
              <a href="/account-overview" className="text-blue-400">
                Account Page
              </a>
              ). Use the Personal Login Token or the QR code.
            </li>
            <li>Enable push notifications.</li>
          </ul>

          <p className="mt-12 text-2xl font-semibold text-neutral-200">
            How to activate Discord TX Notifications?
          </p>
          <ul className="mt-4 ml-3 space-y-1 text-base list-decimal list-inside text-neutral-200">
            <li>
              Go to your{' '}
              <a href="/account-overview" className="text-blue-400">
                Account Page
              </a>
              .
            </li>
            <li>
              Discord Connection -&gt; Transaction Notifications -&gt; Activate
              TX Notifications.
            </li>
            <li>Open your Discord.</li>
            <li>
              Right-Click Poki Server Icon -&gt; Privacy Settings -&gt; Allow
              Direct Messages from other members of the Server.
            </li>
          </ul>

          <p className="mt-12 text-2xl font-semibold text-neutral-200">
            Specific NFT collections are not showing in my Portfolio
          </p>
          <ul className="mt-4 ml-3 space-y-1 text-base list-decimal list-inside text-neutral-200">
            <li>
              Poki only indexes NFT collections automatically once they reach
              30k+ {ADA_SYMBOL} in lifetime volume.
            </li>
            <li>
              If you are a Pass Holder you can add collections manually on your{' '}
              <a href="/account-overview" className="text-blue-400">
                Account Page
              </a>
              .
            </li>
          </ul>

          <p className="mt-12 text-2xl font-semibold text-neutral-200">
            Specific Tokens are not showing in my Portfolio
          </p>
          <ul className="mt-4 ml-3 space-y-1 text-base list-decimal list-inside text-neutral-200">
            <li>
              Poki automatically tracks Tokens with 5k+ {ADA_SYMBOL} TVL on
              Minswap.
            </li>
            <li>Other DEXes coming soon.</li>
          </ul>

          <p className="mt-12 text-2xl font-semibold text-neutral-200">
            I have a question that is not answered here
          </p>
          <ul className="mt-4 ml-3 space-y-1 text-base list-decimal list-inside text-neutral-200">
            <li>
              Join our{' '}
              <a href="https://discord.gg/qPAp3eXs7X" className="text-blue-400">
                Discord
              </a>{' '}
              and ask in the #support-ticket channel.
            </li>
            <li>
              Send us an email to{' '}
              <a href="mailto: lex@getpoki.com" className="text-blue-400">
                lex@getpoki.com
              </a>
              .
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
