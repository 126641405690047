import React, { useState, useEffect } from 'react'
import useApi, { PulseEntity } from '../../hooks/useApi'
import { ADA_SYMBOL, formatNumber, intToString } from '../../util/format'
import { useNavigate } from 'react-router-dom'

interface Trend {
  [key: string]: string
  very_bad: string
  bad: string
  neutral: string
  good: string
  very_good: string
}

const WIDTHS = [
  'w-full',
  'w-10/12',
  'w-9/12',
  'w-8/12',
  'w-7/12',
  'w-6/12',
  'w-5/12',
  'w-4/12',
  'w-3/12',
  'w-2/12',
]
const BG_COLORS = [
  'bg-blue-600',
  'bg-blue-500',
  'bg-blue-400',
  'bg-blue-300',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
  'bg-blue-200',
]

const TREND_COLORS: Trend = {
  very_bad: 'border-red-500',
  bad: 'border-orange-500',
  neutral: 'border-neutral-500',
  good: 'border-blue-500',
  very_good: 'border-green-500',
}

const TREND_NAMES: Trend = {
  very_bad: 'Very Bad 💀',
  bad: 'Bad 📉',
  neutral: 'Neutral 🤝',
  good: 'Good 📈',
  very_good: 'Very Good 🚀',
}

const SENTIMENT_NAMES: Trend = {
  very_bad: 'Very Negative 💀',
  bad: 'Negative 🙁',
  neutral: 'Neutral 🤝',
  good: 'Positive 😀',
  very_good: 'Very Positive 🚀',
}

export const LiveMarket = () => {
  const [marketSentimentTokens, setMarketSentimentTokens] = useState('neutral')
  const [volumeTrendTokens, setVolumeTrendTokens] = useState('neutral')
  const [marketSentimentNfts, setMarketSentimentNfts] = useState('neutral')
  const [volumeTrendNfts, setVolumeTrendNfts] = useState('neutral')
  const [topTokens, setTopTokens] = useState([] as PulseEntity[])
  const [topNfts, setTopNfts] = useState([] as PulseEntity[])

  const { getPulse } = useApi()
  const navigate = useNavigate()
  const fetchPulse = async () => {
    try {
      const {
        tokens: tokens,
        nfts: nfts,
        nfts_market_sentiment: nftsMarketSentiment,
        tokens_market_sentiment: tokensMarketSentiment,
        nfts_volume_trend: nftsVolumeTrend,
        tokens_volume_trend: tokensVolumeTrend,
      } = await getPulse()
      setTopTokens(tokens || [])
      setTopNfts(nfts || [])
      setMarketSentimentNfts(nftsMarketSentiment || 'neutral')
      setVolumeTrendNfts(nftsVolumeTrend || 'neutral')
      setMarketSentimentTokens(tokensMarketSentiment || 'neutral')
      setVolumeTrendTokens(tokensVolumeTrend || 'neutral')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchPulse()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchPulse()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
      <div className="flex flex-col w-full gap-2 p-10 pt-32 pb-20 2xl:w-5/6 text-neutral-200">
        <p className="text-6xl font-bold text-neutral-200">Cardano Pulse</p>

        <div className="flex flex-col w-full gap-8 mt-8 md:flex-row">
          <div className="flex flex-col w-full gap-4 md:w-1/2">
            <div className="mb-2">
              <p className="text-lg font-semibold tracking-wide text-neutral-400">
                Top Selling NFTs (1h) 📈
              </p>

              <div className="grid grid-cols-2 gap-3 mt-4">
                <div
                  className={`border ${TREND_COLORS[volumeTrendNfts]} py-6 rounded-lg justify-center flex`}>
                  <p className="self-center font-semibold tracking-wide text-neutral-200">
                    Volume Trend:{' '}
                    <span className="font-normal">
                      {TREND_NAMES[volumeTrendNfts]}
                    </span>
                  </p>
                </div>

                <div
                  className={`border ${TREND_COLORS[marketSentimentNfts]} py-2 px-3 rounded-lg justify-center flex`}>
                  <p className="self-center font-semibold tracking-wide text-neutral-200">
                    Market Sentiment:{' '}
                    <span className="font-normal">
                      {SENTIMENT_NAMES[marketSentimentNfts]}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {topNfts.length !== 10 && (
              <div className="relative flex flex-row justify-center gap-3 p-5 text-xs font-semibold border border-stone-800 bg-stone-900 rounded-xl text-neutral-300 md:h-40">
                <p className="self-center text-neutral-400">
                  Top Selling Data is currently incomplete or not available. We
                  are investigating a solution.
                </p>
              </div>
            )}
            {topNfts.map((collection, index) => {
              return (
                <div
                  className={`flex flex-row w-full text-xs font-semibold text-neutral-200 rounded-xl hover:cursor-pointer hover:bg-stone-800 bg-stone-900 border-stone-800 border`}
                  key={`top-selling-${index}`}
                  onClick={() => navigate(`/projects/${collection.slug}`)}>
                  <img
                    src={collection.image_url}
                    className="self-center object-cover w-16 h-full rounded-l-lg"
                  />

                  <div className="flex flex-col w-full px-3 pt-1 pb-2 text-neutral-200">
                    <p className="text-sm font-bold leading-6 tracking-wide text-neutral-200">
                      {collection.display_name}
                    </p>

                    <div className="flex flex-row gap-2 mt-1 md:gap-4">
                      <div className="flex flex-row gap-1 px-2 py-1 rounded-lg bg-neutral-800">
                        <p className="self-center text-xs text-neutral-400">
                          Vol.:
                        </p>
                        <p className="self-center text-xs font-bold">
                          {intToString(collection.volume)}
                        </p>
                      </div>
                      <div className="flex flex-row gap-1 px-2 py-1 rounded-lg bg-neutral-800">
                        <p className="self-center text-xs text-neutral-400">
                          Wallets:
                        </p>
                        <p className="self-center text-xs font-bold">
                          {collection.unique_addr_count}
                        </p>
                      </div>

                      <div className="flex flex-row gap-1 px-2 py-1 rounded-lg bg-neutral-800">
                        <p className="self-center text-xs text-neutral-400">
                          Floor:
                        </p>
                        <p className="self-center text-xs font-bold">
                          {intToString(collection.current_price)}
                        </p>
                      </div>
                    </div>

                    <div
                      className={`h-3 ${BG_COLORS[index]} ${WIDTHS[index]} mt-2 rounded-r-lg`}
                    />
                  </div>
                </div>
              )
            })}
          </div>

          <div className="flex flex-col w-full md:w-1/2">
            <p className="text-lg font-semibold tracking-wide text-neutral-400">
              Top Selling Tokens (1h) 📈
            </p>

            <div className="grid grid-cols-2 gap-3 mt-4 mb-6">
              <div
                className={`border ${TREND_COLORS[volumeTrendTokens]} py-6 rounded-lg justify-center flex`}>
                <p className="self-center font-semibold tracking-wide text-neutral-200">
                  Volume Trend:{' '}
                  <span className="font-normal">
                    {TREND_NAMES[volumeTrendTokens]}
                  </span>
                </p>
              </div>

              <div
                className={`border ${TREND_COLORS[marketSentimentTokens]} py-2 px-3 rounded-lg justify-center flex`}>
                <p className="self-center font-semibold tracking-wide text-neutral-200">
                  Market Sentiment:{' '}
                  <span className="font-normal">
                    {SENTIMENT_NAMES[marketSentimentTokens]}
                  </span>
                </p>
              </div>
            </div>

            {topTokens.length === 0 && (
              <div className="relative flex flex-row justify-center gap-3 p-5 text-xs font-semibold border border-stone-800 bg-stone-900 rounded-xl text-neutral-300 md:h-40">
                <p className="self-center text-neutral-400">
                  Top Selling Data is currently incomplete or not available. We
                  are investigating a solution.
                </p>
              </div>
            )}
            <div className="flex flex-col gap-4">
              {topTokens.map((token, index) => {
                return (
                  <div
                    className={`flex flex-row w-full text-xs font-semibold text-neutral-200 rounded-xl hover:cursor-pointer hover:bg-stone-800 bg-stone-900 border-stone-800 border`}
                    key={`top-selling-${index}`}
                    onClick={() => navigate(`/projects/${token.slug}`)}>
                    <img
                      src={token.image_url}
                      className="self-center object-cover w-16 h-full rounded-l-lg"
                    />

                    <div className="flex flex-col w-full px-3 pt-1 pb-2 text-neutral-200">
                      <p className="text-sm font-bold leading-6 tracking-wide text-neutral-200">
                        {token.display_name}
                      </p>

                      <div className="flex flex-row gap-4 mt-2">
                        <p className="self-start px-2 py-1 text-xs font-bold rounded-lg bg-neutral-800">
                          Volume: {ADA_SYMBOL} {intToString(token.volume)}
                        </p>

                        <p className="self-start px-2 py-1 text-xs font-bold rounded-lg bg-neutral-800">
                          Price: {ADA_SYMBOL}{' '}
                          {formatNumber(token.current_price, 8)}
                        </p>
                      </div>

                      <div className="flex flex-row gap-4 pl-1 mt-2">
                        <div className="flex flex-col gap-1 font-bold">
                          <p className="text-green-500">
                            {formatNumber(token.buying_orders_percentage, 0)}%
                            Buying
                          </p>
                          <div className="self-start w-32 bg-neutral-700">
                            <div
                              className="w-full h-1 bg-green-500"
                              style={{
                                width: `${token.buying_orders_percentage}%`,
                              }}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col gap-1 font-bold">
                          <p className="text-red-500">
                            {formatNumber(token.selling_orders_percentage, 0)}%
                            Selling
                          </p>
                          <div className="self-start w-32 bg-neutral-700">
                            <div
                              className="w-full h-1 bg-red-500"
                              style={{
                                width: `${token.selling_orders_percentage}%`,
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row gap-1 mt-2"></div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
