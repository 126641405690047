import React, { useEffect, useState } from 'react'
import {
  IoClose,
  IoMenu,
  IoPieChartSharp,
  IoWalletSharp,
  IoBarChartSharp,
  IoPulseSharp,
  IoSearchOutline,
} from 'react-icons/io5'
import { useNavigate, useLocation } from 'react-router-dom'
import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { useUser } from '../../context/UserContext'
import { RoutePaths } from '../../route-paths'
import useApi, { ProjectEntity } from '../../hooks/useApi'
import {
  ADA_SYMBOL,
  formatNumber,
  intToString,
  numberOfLeadingZeroes,
} from '../../util/format'
import { PulseLoader } from 'react-spinners'

export const Header: React.FC = () => {
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([] as ProjectEntity[])
  const [isFetching, setIsFetching] = useState(false)
  const [noResults, setNoResults] = useState(false)
  const [_, setApiError] = useState('')
  const [showSearchResults, setShowSearchResults] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  const { user, setUser } = useUser()
  const navigate = useNavigate()
  const location = useLocation()

  const { getMarketSearch } = useApi()

  const onLogout = () => setUser({})
  const onClickBars = () => setShowMobileNav(!showMobileNav)

  const isCurrentLocation = (path: string) => path === location.pathname

  const fetchSearchResults = async () => {
    if (isFetching) return

    try {
      setIsFetching(true)
      setSearchResults([])
      const response = await getMarketSearch(debouncedSearchQuery)
      if (!response.error) {
        setSearchResults(response.market_assets || [])
        if (response.market_assets?.length === 0) {
          setNoResults(true)
        } else {
          setNoResults(false)
        }
        setApiError('')
      } else {
        setApiError(response.error)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    if (searchQuery === '') return

    setIsTyping(true)
    let debounceTimer: NodeJS.Timeout

    const debounceTime = 500

    if (searchQuery.length > 0) {
      debounceTimer = setTimeout(() => {
        setDebouncedSearchQuery(searchQuery)
        setIsTyping(false)
      }, debounceTime)
    } else {
      setIsTyping(false)
    }

    return () => {
      clearTimeout(debounceTimer)
    }
  }, [searchQuery])

  useEffect(() => {
    if (debouncedSearchQuery === '') return

    fetchSearchResults()
  }, [debouncedSearchQuery])

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value)

  const decimalPlacesCount = (price: number) => {
    const leadingZeroesCount = numberOfLeadingZeroes(price)

    if (leadingZeroesCount === 0 && price < 1) return 3
    if (price >= 1) return 2

    return leadingZeroesCount + 3
  }

  const resetSearch = () => {
    setSearchQuery('')
    setSearchResults([])
    setNoResults(false)
  }

  const handleClickOutsideSearch = (e: any) => {
    if (e.relatedTarget?.classList.contains('prevent-close')) return

    setShowSearchResults(false)
  }

  return (
    <>
      <div className="fixed top-0 left-0 z-20 justify-between w-full px-10 pt-5 pb-5 tracking-wider border-b bg-neutral-900 bg-opacity-95 border-neutral-800">
        <div className="flex flex-row justify-between w-full gap-5">
          <div className="flex flex-row w-full gap-10 lg:w-1/2">
            <img
              className="self-center w-10 hover:cursor-pointer"
              onClick={() => navigate(RoutePaths.Root)}
              src="/images/logo_small.png"
            />

            <div className="relative flex flex-row w-full h-12 gap-3 px-4 py-3 border focus-within:border-neutral-700 rounded-xl border-neutral-800">
              <IoSearchOutline
                size={20}
                className="self-center text-neutral-400"
              />
              <input
                onBlur={handleClickOutsideSearch}
                onFocus={() => setShowSearchResults(true)}
                onChange={handleSearchQueryChange}
                value={searchQuery}
                className="self-center w-full bg-transparent outline-none text-neutral-200"
                placeholder="Search Tokens or NFTs"
              />

              {searchQuery.length > 0 && (
                <IoClose
                  size={20}
                  className="text-white cursor-pointer"
                  onClick={resetSearch}
                />
              )}

              {searchQuery.length > 0 && showSearchResults && (
                <div className="absolute left-0 z-10 w-full pt-2 overflow-auto border top-12 max-h-96 rounded-xl bg-neutral-900 border-neutral-800">
                  {(isFetching || isTyping) && (
                    <div className="flex flex-row justify-center py-3">
                      <PulseLoader
                        color={'#a3a3a3'}
                        loading={true}
                        size={8}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </div>
                  )}

                  {!isFetching && !isTyping && noResults && (
                    <div className="flex flex-row justify-center py-3">
                      <p className="self-center text-sm text-neutral-400">
                        No Results
                      </p>
                    </div>
                  )}

                  {!noResults &&
                    searchQuery.length > 0 &&
                    !isTyping &&
                    searchResults.length > 0 && (
                      <div className="flex flex-col w-full">
                        <div className="flex flex-row justify-between px-4 py-2">
                          <div className="flex flex-row w-full">
                            <div className="flex flex-row self-center justify-between w-full gap-5 text-white">
                              <div className="flex flex-row w-3/4 gap-4 self-centrer">
                                <p className="self-center text-xs truncate text-neutral-400">
                                  Name
                                </p>
                              </div>
                              <div className="flex flex-row self-center justify-end w-1/4 text-xs text-right text-neutral-400">
                                <p>Price / Volume</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {searchResults.map((result, index) => (
                          <a
                            href={`/projects/${result.slug}`}
                            key={index}
                            className="flex flex-row justify-between cursor-pointer prevent-close hover:bg-neutral-800">
                            <div className="flex flex-row w-full px-4 py-2">
                              <div className="flex flex-row self-center justify-between w-full gap-5 text-white">
                                <div className="flex flex-row w-3/4 gap-4 self-centrer">
                                  <img
                                    className="self-center rounded-full w-9 h-9"
                                    src={result.image_url}
                                  />

                                  <p className="self-center text-sm truncate">
                                    {result.display_name}
                                  </p>
                                </div>
                                <div className="flex flex-col self-center justify-end w-1/4 text-xs text-right truncate text-neutral-200">
                                  <p className="text-sm font-bold">
                                    {ADA_SYMBOL}{' '}
                                    {formatNumber(
                                      result.current_price,
                                      decimalPlacesCount(result.current_price),
                                    )}{' '}
                                  </p>

                                  <p className="text-neutral-500">
                                    {ADA_SYMBOL}{' '}
                                    {intToString(result.volume_24h)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
          <div className="flex-row justify-end hidden w-full lg:flex">
            <div className="flex flex-row self-center gap-8">
              <div
                className={`self-center flex ml-5 flex-row gap-2 text-xs font-bold hover:cursor-pointer hover:text-white
              ${
                isCurrentLocation('/portfolio')
                  ? 'underline text-white'
                  : 'text-neutral-200'
              }`}
                onClick={() => navigate(RoutePaths.Portfolio)}>
                <IoPieChartSharp size={18} />
                <p className="self-center">PORTFOLIO</p>
              </div>

              {user?.token && (
                <div
                  className={`self-center flex justify-center flex-row gap-2 text-xs font-bold hover:cursor-pointer
                ${
                  isCurrentLocation('/insights')
                    ? 'underline text-white'
                    : 'text-neutral-200'
                }`}
                  onClick={() => navigate(RoutePaths.Insights)}>
                  <IoBarChartSharp size={18} className="self-center" />
                  <p className="self-center">INSIGHTS</p>
                </div>
              )}

              <div
                className={`self-center flex justify-center flex-row gap-2 text-xs font-bold hover:cursor-pointer
              ${
                isCurrentLocation('/pulse')
                  ? 'underline text-white'
                  : 'text-neutral-200'
              }`}
                onClick={() => navigate(RoutePaths.Pulse)}>
                <IoPulseSharp size={18} className="self-center" />
                <p className="self-center">PULSE</p>
              </div>

              {user?.token && (
                <div
                  className={`self-center flex justify-center flex-row gap-2 text-xs font-bold hover:cursor-pointer
                ${
                  isCurrentLocation('/account-overview')
                    ? 'underline text-white'
                    : 'text-neutral-200'
                }`}
                  onClick={() => navigate(RoutePaths.AccountOverview)}>
                  <IoWalletSharp size={18} className="self-center" />
                  <p className="self-center">ACCOUNT</p>
                </div>
              )}

              {user.token ? (
                <button
                  className="self-center px-5 py-2 text-xs font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                  onClick={onLogout}>
                  Logout
                </button>
              ) : (
                <button
                  className="self-center px-5 py-3 text-xs font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                  onClick={() => navigate(RoutePaths.ConnectWallet)}>
                  Connect Wallet
                </button>
              )}
            </div>
          </div>

          {!showMobileNav && (
            <div className="flex flex-row lg:hidden">
              <p
                className="self-center text-white hover:cursor-pointer"
                onClick={onClickBars}>
                <IoMenu size={22} />
              </p>
            </div>
          )}
        </div>

        {showMobileNav && (
          <div className="fixed top-0 left-0 w-full h-screen overflow-hidden text-neutral-200 bg-neutral-900">
            <p
              className="absolute text-white top-5 hover:cursor-pointer right-5 lg:hidden"
              onClick={onClickBars}>
              <IoClose size={20} />
            </p>

            <div className="flex flex-col w-full h-screen gap-10 px-10 pt-10 text-lg uppercase">
              <div className="pb-5 border-b border-neutral-800">
                <img
                  className="w-10 hover:cursor-pointer"
                  onClick={() => navigate(RoutePaths.Root)}
                  src="/images/logo_small.png"
                />
              </div>

              <div
                className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer hover:text-white
                  ${
                    isCurrentLocation('/')
                      ? 'underline text-white'
                      : 'text-neutral-200'
                  }`}
                onClick={() => {
                  onClickBars()
                  navigate(RoutePaths.Root)
                }}>
                <p>HOME</p>
              </div>

              <div
                className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer hover:text-white
                  ${
                    isCurrentLocation('/portfolio')
                      ? 'underline text-white'
                      : 'text-neutral-200'
                  }`}
                onClick={() => {
                  onClickBars()
                  navigate(RoutePaths.Portfolio)
                }}>
                <IoPieChartSharp size={18} />
                <p>PORTFOLIO</p>
              </div>

              {user?.token && (
                <div
                  className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer
                    ${
                      isCurrentLocation('/insights')
                        ? 'underline text-white'
                        : 'text-neutral-200'
                    }`}
                  onClick={() => {
                    onClickBars()
                    navigate(RoutePaths.Insights)
                  }}>
                  <IoBarChartSharp size={18} className="" />
                  <p>INSIGHTS</p>
                </div>
              )}

              <div
                className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer
                ${
                  isCurrentLocation('/pulse')
                    ? 'underline text-white'
                    : 'text-neutral-200'
                }`}
                onClick={() => {
                  onClickBars()
                  navigate(RoutePaths.Pulse)
                }}>
                <IoPieChartSharp size={18} className="self-center" />
                <p className="self-center">Pulse</p>
              </div>

              {user?.token && (
                <div
                  className={`flex flex-row gap-2 text-sm font-bold hover:cursor-pointer
                    ${
                      isCurrentLocation('/account-overview')
                        ? 'underline text-white'
                        : 'text-neutral-200'
                    }`}
                  onClick={() => {
                    onClickBars()
                    navigate(RoutePaths.AccountOverview)
                  }}>
                  <IoWalletSharp size={18} className="" />
                  <p>ACCOUNT OVERVIEW</p>
                </div>
              )}

              <div className="flex flex-row gap-8">
                <a
                  href="https://discord.gg/qPAp3eXs7X"
                  rel="noreferrer"
                  target="_blank"
                  className="self-center text-indigo-600">
                  <FaDiscord size={30} />
                </a>

                <a
                  href="https://twitter.com/poki_portfolio"
                  rel="noreferrer"
                  target="_blank"
                  className="self-center text-blue-500">
                  <FaTwitter size={30} />
                </a>
              </div>
              {user.token ? (
                <button
                  className="self-center w-full px-5 py-3 mt-10 text-sm font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                  onClick={() => {
                    onClickBars()
                    onLogout()
                  }}>
                  Logout
                </button>
              ) : (
                <button
                  className="self-center w-full px-5 py-3 mt-10 text-sm font-bold text-white rounded-lg bg-neutral-800 hover:bg-neutral-600"
                  onClick={() => {
                    onClickBars()
                    navigate(RoutePaths.ConnectWallet)
                  }}>
                  Connect Wallet
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
