export enum RoutePaths {
  AccountOverview = '/account-overview',
  ConnectWallet = '/connect-wallet',
  Contact = '/contact',
  DebugLogin = '/debug-login',
  DiscordOauth = '/discord-oauth',
  Faq = '/faq',
  Insights = '/insights',
  LifetimePass = '/lifetime-pass',
  LiveMarket = '/live-market',
  MagicLogin = '/magic-login',
  Portfolio = '/portfolio',
  Project = '/projects/:id',
  Privacy = '/privacy',
  Pulse = '/pulse',
  Roadmap = '/roadmap',
  Root = '/',
}
