import React from 'react'
import { FaDiscord, FaTwitter } from 'react-icons/fa'

export const Footer: React.FC = () => {
  return (
    <>
      <div className="flex flex-row justify-center pt-8 border-t border-neutral-800 bg-neutral-900">
        <div className="grid w-full grid-cols-1 gap-10 p-10 pb-20 md:grid-cols-2 xl:w-2/3">
          <div className="flex flex-col">
            <img src="/images/logo.png" className="w-24 " />
            <div className="flex flex-col mt-4 text-sm leading-5 tracking-wide text-neutral-200">
              <p>
                Elevate your Cardano experience with Poki, the premier native
                Mobile Cardano Portfolio Tracking App available on iOS and
                Android. Poki stands out as the only app offering seamless Token
                Tracking, NFT Tracking, Mobile Push Notifications, Price Alerts,
                and aggregation of all your Wallets data.
              </p>

              <p className="mt-4">
                Discover the convenience of Poki&apos;s comprehensive features
                designed for both iOS and Android users. Effortlessly track
                tokens and NFTs, receive Mobile Push Notifications, set up Price
                Alerts, and access an aggregated view of all your Wallets.
              </p>
            </div>
          </div>

          <div className="flex flex-col md:items-end">
            <p className="text-xl font-bold text-neutral-400">About</p>
            <a href="/roadmap" className="mt-4 font-semibold text-neutral-200">
              Roadmap
            </a>
            <a href="/faq" className="mt-2 font-semibold text-neutral-200">
              FAQ
            </a>
            <a href="/contact" className="mt-2 font-semibold text-neutral-200">
              Contact
            </a>
            <a href="/privacy" className="mt-2 font-semibold text-neutral-200">
              Privacy
            </a>

            <div className="flex flex-row gap-4 mt-8">
              <a
                href="https://discord.gg/qPAp3eXs7X"
                rel="noreferrer"
                target="_blank"
                className="self-center text-indigo-600">
                <FaDiscord size={30} />
              </a>

              <a
                href="https://twitter.com/poki_portfolio"
                rel="noreferrer"
                target="_blank"
                className="self-center text-blue-500">
                <FaTwitter size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
