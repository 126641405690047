import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useUser } from '../../context/UserContext'
import {
  IoCheckmarkCircle,
  IoOpenOutline,
  IoDesktopSharp,
  IoRemoveCircleOutline,
  IoCloseCircle,
  IoBanSharp,
  IoClose,
} from 'react-icons/io5'
import useApi, { Account, ProjectEntity, User } from '../../hooks/useApi'
import { UpgradeModal } from '../../components/UpgradeModal'
import { AddWalletModal } from '../../components/AddWalletModal'
import { RemoveWalletModal } from '../../components/RemoveWalletModal'
import { BounceLoader } from 'react-spinners'
import { AddNftCollectionModal } from '../../components/AddNftCollectionModal'
import Select from 'react-tailwindcss-select'
import { SelectValue } from 'react-tailwindcss-select/dist/components/type'
import { Option } from 'react-tailwindcss-select/dist/components/type'
import { LazyLoadImage } from 'react-lazy-load-image-component'

interface ReferenceOption {
  imageUrl: string
  label: string
  id: number
}

const DISCORD_OAUTH_URL =
  'https://discord.com/api/oauth2/authorize?client_id=1046900278651256902&redirect_uri=https%3A%2F%2Fgetpoki.com%2Fdiscord-oauth&response_type=token&scope=identify'

export const AccountOverview: React.FC = () => {
  const [showUpgradeMessage, setShowUpgradeMessage] = useState(false)
  const [showAddWalletModal, setShowAddWalletModal] = useState(false)
  const [showAddNftCollectionModal, setShowAddNftCollectionModal] =
    useState(false)
  const [isLoading, setLoading] = useState(true)
  const [showRemoveWalletModal, setShowRemoveWalletModal] = useState(false)
  const [walletToRemove, setWalletToRemove] = useState({} as Account)
  const [_user, setUser] = useState({} as User)
  const [upgradeText, setUpgradeText] = useState('')
  const [txesDiscordEnabled, setTxesDiscordEnabled] = useState(false)
  const [
    showActivateTxNotificationsWarning,
    setShowActivateTxNotificationsWarning,
  ] = useState(false)
  const [nftProjectToHide, setNftProjectToHide] = useState(null as SelectValue)
  const [nftProjects, setNftProjects] = useState([] as Option[])
  const [referenceNftProjects, setReferenceNftProjects] = useState(
    [] as ReferenceOption[],
  )
  const [hiddenNftProjects, setHiddenNftProjects] = useState(
    [] as ProjectEntity[],
  )

  const {
    getUser,
    updateWallet,
    updateNotificationConfig,
    getUserNftProjects,
    hideNftProject,
    unhideNftProject,
  } = useApi()
  const { user } = useUser()

  useEffect(() => {
    const fetchUser = async () => {
      if (user.id && user.token) {
        try {
          const { user: userFromResponse } = await getUser(user.id, user.token)

          if (userFromResponse) {
            setUser(userFromResponse)
            setTxesDiscordEnabled(
              userFromResponse?.notification_config?.txes_discord || false,
            )
          } else {
            console.log('An unknown error occured')
          }
        } catch (error) {
          console.log('An unknown error occured')
        } finally {
          setLoading(false)
        }
      }
    }

    fetchUser()
  }, [])

  useEffect(() => {
    const fetchNftProjects = async () => {
      if (user.id && user.token) {
        try {
          const { projects, hidden_projects } = await getUserNftProjects(
            user.token,
          )

          if (hidden_projects) {
            setHiddenNftProjects(hidden_projects)
          }

          if (projects) {
            updateNftProjects(projects)
          } else {
            console.log('An unknown error occured')
          }
        } catch (error) {
          console.log('An unknown error occured')
        } finally {
          setLoading(false)
        }
      }
    }

    fetchNftProjects()
  }, [])

  const onAddWallet = () => {
    if (_user.pro) {
      setShowAddWalletModal(true)
    } else {
      setUpgradeText('In order to add more wallets')
      setShowUpgradeMessage(true)
    }
  }

  const onAddNftCollection = () => {
    if (_user.pro) {
      setShowAddNftCollectionModal(true)
    } else {
      setUpgradeText('In order to add new NFT Collections')
      setShowUpgradeMessage(true)
    }
  }

  const onRemoveWallet = (walletData: Account) => {
    setWalletToRemove(walletData)
    setShowRemoveWalletModal(true)
  }

  const onActivateNode = async (walletData: Account) => {
    if (_user.pro) {
      if (!_user.token) return

      const response = await updateWallet(walletData.id, true, _user.token)

      if (response.error) {
        console.log('An unknown error occured')
      } else {
        setUser({ ..._user, ...{ accounts: response.accounts } })
      }
    } else {
      setUpgradeText('In order to use the Poki Custom Node')
      setShowUpgradeMessage(true)
    }
  }

  const onActivateTxNotifications = async () => {
    if (_user.pro) {
      if (!_user.token) return

      if (!_user.discord_id) {
        setShowActivateTxNotificationsWarning(true)
        return
      } else {
        setShowActivateTxNotificationsWarning(false)
      }

      const response = await updateNotificationConfig(
        { txes_discord: !txesDiscordEnabled },
        _user.token,
      )

      if (response.error) {
        console.log('An unknown error occured')
      } else {
        setTxesDiscordEnabled(response.notification_config.txes_discord)
      }
    } else {
      setUpgradeText('In order to get Transaction Notifications in Discord')
      setShowUpgradeMessage(true)
    }
  }

  const magicLoginLink = () => {
    if (process.env.REACT_APP_MOBILE_LOGIN_LINK_ACTIVE == 'true') {
      return `pokiportfolio://magicLogin?token=${_user.token}`
    } else {
      return 'Coming soon! Follow us on Twitter & Discord for updates.'
    }
  }

  const onVerifyHolder = () => {
    if (_user.pro) {
      window.location.assign(DISCORD_OAUTH_URL)
    } else {
      setUpgradeText('In order to get the Pass Holder Role in Discord')
      setShowUpgradeMessage(true)
    }
  }

  const updateNftProjects = (projects: ProjectEntity[]) => {
    const referenceOptions = projects.map((project) => {
      return {
        label: project.display_name,
        imageUrl: project.image_url as string,
        id: project.id,
      }
    })

    const options = projects.map((project) => {
      return { value: project.id.toString(), label: project.display_name }
    })

    setReferenceNftProjects(referenceOptions)
    setNftProjects(options)
  }

  const onCloseRemoveWalletModal = () => setShowRemoveWalletModal(false)
  const onCloseAddWalletModal = () => setShowAddWalletModal(false)
  const onCloseAddNftCollectionModal = () => setShowAddNftCollectionModal(false)
  const onCloseUpgradeModal = () => setShowUpgradeMessage(false)
  const mainWalletBorderColor = () =>
    _user.pro ? 'border-purple-400' : 'border-orange-400'

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">
            Account Overview
          </p>

          <div className="flex flex-row gap-2 mt-12 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  const handleNftProjectToHideChange = async (value: SelectValue) => {
    const projectId = referenceId(value as Option)
    if (projectId && _user.token) {
      const response = await hideNftProject(projectId, _user.token)

      if (response.error) {
        console.log('An unknown error occured')
      } else if (response.projects && response.hidden_projects) {
        updateNftProjects(response.projects)
        setHiddenNftProjects(response.hidden_projects)
      }
    }
  }

  const handleUnhideNftCollection = async (projectId: number) => {
    if (_user.token) {
      const response = await unhideNftProject(projectId, _user.token)

      if (response.error) {
        console.log('An unknown error occured')
      } else if (response.projects && response.hidden_projects) {
        updateNftProjects(response.projects)
        setHiddenNftProjects(response.hidden_projects)
      }
    }
  }

  const referenceImage = (data: Option) => {
    const reference = referenceNftProjects.find(
      (project) => project.label === data.label,
    )
    return reference?.imageUrl
  }

  const referenceId = (data: Option) => {
    const reference = referenceNftProjects.find(
      (project) => project.label === data.label,
    )
    return reference?.id
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        {showUpgradeMessage && (
          <UpgradeModal onClose={onCloseUpgradeModal} text={upgradeText} />
        )}

        {showAddWalletModal && (
          <AddWalletModal onClose={onCloseAddWalletModal} />
        )}

        {showAddNftCollectionModal && (
          <AddNftCollectionModal onClose={onCloseAddNftCollectionModal} />
        )}

        {showRemoveWalletModal && (
          <RemoveWalletModal
            {...walletToRemove}
            onClose={onCloseRemoveWalletModal}
          />
        )}

        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">
            Account Overview
          </p>

          <div className="flex flex-col mt-12">
            <div className="flex flex-col justify-between w-full gap-5 p-6 border rounded-lg md:flex-row border-stone-800">
              <div className="flex flex-col gap-5">
                <div>
                  <p className="text-xl font-bold text-neutral-200">
                    Poki Access Level
                  </p>
                  <p
                    className={`font-bold text-white text-lg ${
                      _user.pro ? 'text-purple-500' : 'text-orange-500'
                    }`}>
                    {_user.pro ? 'Pro' : 'Basic'}
                  </p>
                </div>

                <div>
                  <p className="font-bold text-neutral-200">
                    Get Poki Pro on Mobile
                  </p>
                  <div className="flex flex-col gap-1 mt-1 text-xs text-neutral-400">
                    <p>
                      <strong>1.</strong> Download the Poki Mobile App.
                    </p>
                    <p>
                      <strong>2.</strong> Open the System Camera App on your
                      Smartphone & scan the QR code from this page. Poki will
                      open and you will get logged in.
                    </p>
                    <strong className="text-neutral-200">OR</strong>
                    <p>
                      <strong>3.</strong> Open the Poki App and enter your
                      Personal Login Token (shown below) in the Login Screen
                      when prompted for your address or $handle.
                    </p>
                  </div>
                </div>

                <div>
                  <p className="font-bold text-neutral-200">
                    Personal Login Token
                  </p>
                  <div className="inline-flex px-4 py-3 mt-2 bg-neutral-800">
                    <p className="text-xs font-bold text-neutral-200">
                      ${_user.app_key}
                    </p>
                  </div>
                </div>
              </div>

              <div className="self-start p-2 bg-white">
                <QRCode value={magicLoginLink()} size={180} />
              </div>
            </div>
          </div>

          <div className="p-6 mt-10 border rounded-lg border-stone-800">
            <p className="text-xl font-bold text-neutral-200">
              Discord Connection
            </p>

            <p className="mt-6 font-bold text-neutral-200">Holder Role</p>
            <p className="mt-1 text-xs text-neutral-400">
              Connect your Discord Account to Poki to get the Pass Holder Role
              in Discord.
            </p>

            <div className="flex flex-col mt-4">
              {!_user.discord_id && (
                <button
                  className="self-start px-5 py-3 bg-purple-700 rounded-lg hover:bg-purple-500"
                  onClick={onVerifyHolder}>
                  <p className="text-xs font-bold text-white">
                    Get Holder Role
                  </p>
                </button>
              )}

              {_user.discord_id && (
                <button
                  disabled
                  className="self-start px-3 py-3 rounded-lg bg-neutral-800">
                  <div className="flex flex-row justify-center gap-1">
                    <IoCheckmarkCircle
                      size={17}
                      className="self-center text-green-400"
                    />

                    <p className="self-center text-xs font-bold text-green-400">
                      Holder Role Verified
                    </p>
                  </div>
                </button>
              )}
            </div>

            <p className="mt-6 font-bold text-neutral-200">
              Transaction Notifications
            </p>

            <p className="mt-1 text-xs text-neutral-400">
              Get notified by our Discord Bot when you receive or send ADA or
              assets.
            </p>

            {!txesDiscordEnabled && (
              <button
                className="self-start px-5 py-3 mt-4 bg-purple-700 rounded-lg hover:bg-purple-500"
                onClick={onActivateTxNotifications}>
                <p className="text-xs font-bold text-white">
                  Activate TX Notifications
                </p>
              </button>
            )}

            {txesDiscordEnabled && (
              <div className="flex flex-col gap-2 mt-4">
                <button
                  className="self-start px-3 py-3 rounded-lg bg-neutral-700 hover:bg-red-500"
                  onClick={onActivateTxNotifications}>
                  <div className="flex flex-row justify-center gap-1">
                    <IoBanSharp size={17} className="self-center text-white" />

                    <p className="self-center text-xs font-bold text-white">
                      Deactivate TX Notifications
                    </p>
                  </div>
                </button>
                <p className="mt-2 text-xs font-bold text-orange-400">
                  Attention! 🚨 You need to allow the Poki Bot to send messages
                  to you.
                </p>

                <p className="text-xs text-orange-400">
                  In Discord: Right-Click Poki Server Icon -&gt; Privacy
                  Settings -&gt; Allow Direct Messages from other members of the
                  Server
                </p>
              </div>
            )}

            {showActivateTxNotificationsWarning && (
              <p className="mt-2 text-xs text-red-400">
                Error: You must get your Discord Holder Role first. Click on
                &quot;Get Holder Role&quot; above to get it.
              </p>
            )}
          </div>

          <div className="p-6 mt-10 text-white border rounded-lg border-stone-800">
            <p className="text-xl font-bold">Your Portfolio</p>

            <p className="mt-6 font-bold text-neutral-200">
              Add NFT Collection
            </p>

            <p className="mt-1 text-xs text-neutral-400">
              Missing a NFT Collection in Poki? Submit the Policy ID here and it
              will be added to Poki within 1 hour.
            </p>

            <button
              className="self-start px-5 py-3 mt-4 bg-purple-700 rounded-lg hover:bg-purple-500"
              onClick={onAddNftCollection}>
              <p className="text-xs font-bold text-white">Add NFT Collection</p>
            </button>

            <p className="mt-6 font-bold text-neutral-200">
              Hide NFT Collections
            </p>

            <p className="mt-1 text-xs text-neutral-400">
              If you want to exclude a NFT Collection from your Portfolio
              valuation, you can do so here.
            </p>

            <div className="flex mt-4 lg:w-1/2">
              <Select
                classNames={{ tagItemText: 'font-medium' }}
                formatOptionLabel={(data) => (
                  <div
                    className={`flex flex-row items-center justify-center w-full gap-2 mt-1 mb-2 rounded-lg p-1 hover:bg-neutral-200
                  ${data.isSelected ? 'bg-neutral-300' : ''}`}>
                    <LazyLoadImage
                      src={referenceImage(data)}
                      alt={data.label}
                      className="w-8 h-8 rounded-full"
                    />
                    <span
                      className={`block tracking-wide transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded font-medium text-md w-full`}>
                      {data.label}
                    </span>
                  </div>
                )}
                isSearchable
                primaryColor="purple"
                value={nftProjectToHide}
                onChange={handleNftProjectToHideChange}
                options={nftProjects}
              />
            </div>

            <p className="mt-4 text-xs text-neutral-400">
              Currently Hidden NFT Collections: {hiddenNftProjects.length}
            </p>

            {hiddenNftProjects.length > 0 && (
              <div className="grid grid-cols-1 gap-3 mt-2 md:grid-cols-2 lg:grid-cols-4">
                {hiddenNftProjects.map((project, index) => {
                  return (
                    <div
                      key={`hidden-nft-${index}`}
                      className="flex flex-row items-center justify-between w-full p-2 rounded-lg bg-neutral-800">
                      <div className="flex flex-row items-center w-5/6 gap-2">
                        <LazyLoadImage
                          src={project.image_url}
                          alt={project.display_name}
                          className="rounded-full w-7 h-7"
                        />
                        <p className="text-sm font-medium text-white truncate">
                          {project.display_name}
                        </p>
                      </div>
                      <button
                        onClick={() => handleUnhideNftCollection(project.id)}>
                        <IoClose size={20} className="text-white" />
                      </button>
                    </div>
                  )
                })}
              </div>
            )}
          </div>

          <p className="mt-10 text-xl font-bold text-white">Your Wallets</p>

          <div className="grid w-full grid-cols-1 gap-5 mt-4 md:grid-cols-2 2xl:grid-cols-3">
            {_user.accounts &&
              _user.accounts
                .sort((a, b) => a.id - b.id)
                .map((account, index) => {
                  return (
                    <div
                      key={`wallet-${index}`}
                      className={`flex flex-col p-3 px-5 pb-5 mb-8 border bg-stone-900
                  rounded-lg ${
                    _user.stake_addr === account.stake_addr
                      ? mainWalletBorderColor()
                      : 'border-stone-800'
                  }`}>
                      <div className="flex flex-col w-full">
                        <div className="flex flex-row justify-between">
                          <p className="text-sm font-bold text-neutral-400">
                            Wallet #{index + 1}
                          </p>
                        </div>
                        <p
                          className={`text-lg font-bold text-white break-words`}>
                          {_user.stake_addr === account.stake_addr
                            ? 'Main Wallet'
                            : account.name}
                        </p>
                        <div className="flex flex-row text-neutral-400 hover:text-neutral-200">
                          <a
                            href={`https://pool.pm/${account.stake_addr}`}
                            target="_blank"
                            rel="noreferrer"
                            className="truncate">
                            {account.stake_addr}
                          </a>
                          <a
                            href={`https://pool.pm/${account.stake_addr}`}
                            target="_blank"
                            rel="noreferrer"
                            className="self-center mb-1 ml-1">
                            <IoOpenOutline size={17} />
                          </a>
                        </div>

                        <div className="flex flex-row gap-4">
                          {_user.stake_addr !== account.stake_addr ? (
                            <button
                              className="self-start px-3 py-3 mt-6 rounded-lg bg-neutral-700 hover:bg-red-500 group"
                              onClick={() => onRemoveWallet(account)}>
                              <div className="flex flex-row justify-center gap-1">
                                <IoRemoveCircleOutline
                                  size={17}
                                  className="self-center text-white group-hover:text-white"
                                />
                                <p className="self-center text-xs font-bold text-white">
                                  Remove Wallet
                                </p>
                              </div>
                            </button>
                          ) : (
                            <div className="self-start px-3 py-3 mt-6 rounded-lg bg-neutral-800">
                              {_user.pro ? (
                                <div className="flex flex-row justify-center gap-1">
                                  <IoCheckmarkCircle
                                    size={17}
                                    className="self-center text-purple-500"
                                  />
                                  <p className="self-center text-xs font-bold text-purple-500">
                                    Holds Access Pass
                                  </p>
                                </div>
                              ) : (
                                <div className="flex flex-row justify-center gap-1">
                                  <IoCloseCircle
                                    size={17}
                                    className="self-center text-orange-500"
                                  />
                                  <p className="self-center text-xs font-bold text-orange-500">
                                    No Poki Access Pass
                                  </p>
                                </div>
                              )}
                            </div>
                          )}

                          {account.node_activated === true ? (
                            <button
                              disabled
                              className="self-start px-3 py-3 mt-6 rounded-lg bg-neutral-800">
                              <div className="flex flex-row justify-center gap-1">
                                <IoCheckmarkCircle
                                  size={17}
                                  className="self-center text-green-400"
                                />

                                <p className="self-center text-xs font-bold text-green-400">
                                  Node Activated
                                </p>
                              </div>
                            </button>
                          ) : (
                            <button
                              className="self-start px-3 py-3 mt-6 rounded-lg bg-neutral-700 hover:bg-green-600 group"
                              onClick={() => onActivateNode(account)}>
                              <div className="flex flex-row justify-center gap-2">
                                <IoDesktopSharp
                                  size={17}
                                  className="self-center text-white group-hover:text-white"
                                />

                                <p className="self-center text-xs font-bold text-white">
                                  Activate Node
                                </p>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
          </div>

          <div className="fixed bottom-0 left-0 flex flex-col w-full p-5 border-t bg-neutral-900 border-neutral-800">
            <button
              className="self-center w-1/3 py-4 bg-blue-600 rounded-lg hover:bg-blue-500 px-7"
              onClick={onAddWallet}>
              <p className="text-xs font-bold text-white">Add Wallet</p>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
