import React, { useEffect, useState } from 'react'
import { Address } from '@emurgo/cardano-serialization-lib-browser'
import { Buffer } from 'buffer'
import BounceLoader from 'react-spinners/MoonLoader'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from '../../route-paths'
import useApi, { UserResponse } from '../../hooks/useApi'
import { useUser } from '../../context/UserContext'

/* eslint-disable */
declare global {
  interface Window {
    cardano: any
  }
}
/* eslint-enable */

enum WalletNameEnum {
  eternl = 'eternl',
  nami = 'nami',
  flint = 'flint',
  lace = 'lace',
  vespr = 'vespr',
}

interface WalletIconMapping {
  [key: string]: string
  eternl: string
  nami: string
  flint: string
  lace: string
  vespr: string
}

interface WalletNameMapping {
  [key: string]: string
  eternl: string
  nami: string
  flint: string
  lace: string
}

const WALLET_ICON_MAPPING = {
  eternl: '/images/eternl_logo.png',
  nami: '/images/nami_logo.png',
  flint: '/images/flint_logo.png',
  lace: '/images/lace_logo.png',
  vespr: '/images/vespr_logo.png',
} as WalletIconMapping

const WALLET_NAME_MAPPING = {
  eternl: 'Eternl',
  nami: 'NAMI',
  flint: 'Flint',
  lace: 'Lace',
  vespr: 'VESPR',
} as WalletNameMapping

window.cardano = window.cardano || {}

const LOADING_TEXTS = [
  'Checking for Rugpulls',
  'Activating Degen-Mode',
  'Sweeping the Floor',
  'Initializing FOMO',
  'Blaming Chain Congestion',
]

export const ConnectWallet: React.FC = () => {
  const shuffle = (array: string[]) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
  }

  const [loggingInWith, setLoggingInWith] = useState('')
  const [apiError, setApiError] = useState('')
  const [loadingTexts] = useState(shuffle(LOADING_TEXTS))
  const [loadingText, setLoadingText] = useState(loadingTexts[0])
  const [loadingPercentage, setLoadingPercentage] = useState(0)
  const [_currentIndex, setCurrentIndex] = useState(0)
  const [showLoadingText, setShowLoadingText] = useState(false)

  const { getOrCreateUser } = useApi()
  const { user, setUser } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (user.token) {
      navigate(RoutePaths.Portfolio, { replace: true })
    }
  }, [])

  const startLoadingTexts = () => {
    setShowLoadingText(true)
    const interval = setInterval(() => {
      setLoadingPercentage((prevPercentage) => {
        if (prevPercentage >= 100) {
          setCurrentIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % LOADING_TEXTS.length
            setLoadingText(loadingTexts[nextIndex])
            return nextIndex
          })

          return 0
        } else {
          return prevPercentage + 1
        }
      })
    }, 50)

    return () => clearInterval(interval)
  }

  const enableWallet = async (walletName: WalletNameEnum): Promise<void> => {
    setLoggingInWith(walletName)
    setApiError('')
    const walletApi = await window.cardano[walletName].enable()
    startLoadingTexts()
    const [usedAddress] = await walletApi.getUsedAddresses()

    const decodedAddress = Address.from_bytes(
      Buffer.from(usedAddress, 'hex'),
    ).to_bech32()

    if (decodedAddress) {
      try {
        const response: UserResponse = await getOrCreateUser(decodedAddress)
        const { error, user } = response

        if (error === 'stake_addr_null') {
          setApiError(
            "Can't find stake address. Please try another wallet or contact us.",
          )
        } else if (user) {
          setUser(user)
          navigate(RoutePaths.Portfolio)
        } else {
          setApiError(
            'An unknown error occured. Please try again or contact us.',
          )
        }
      } catch (error) {
        setApiError('An unknown error occured. Please try again or contact us.')
      } finally {
        setLoggingInWith('')
        setShowLoadingText(false)
      }
    }
  }

  return (
    <>
      <div className="flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col justify-center w-full p-10 md:w-2/3 lg:w-1/2">
          <p className="text-6xl font-bold text-neutral-200">
            Connect Wallet holding{' '}
            <span className="text-purple-600">Poki Access Pass</span>
          </p>

          <p className="mt-5 text-sm text-neutral-200">
            If you don&apos;t hold a Poki Access Pass you can still connect your
            wallet, but will be limited to adding a single wallet into your
            Portfolio.
          </p>

          <div className="grid grid-cols-1 gap-5 mt-20 2xl:grid-cols-3 md:grid-cols-2">
            {Object.keys(WalletNameEnum).map((name, index) => (
              <button
                className="px-20 py-3 text-white bg-neutral-800 rounded-xl hover:bg-neutral-700"
                onClick={() =>
                  enableWallet(Object.values(WalletNameEnum)[index])
                }
                disabled={loggingInWith !== ''}
                key={index}>
                <div className="flex flex-row justify-center gap-3">
                  {loggingInWith == name ? (
                    <BounceLoader
                      color={'#ffffff'}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    <img
                      src={WALLET_ICON_MAPPING[name]}
                      className="self-center h-8"
                    />
                  )}

                  <p className="self-center text-sm font-bold text-neutral-200">
                    {WALLET_NAME_MAPPING[name]}
                  </p>
                </div>
              </button>
            ))}
          </div>

          {showLoadingText && (
            <p className="mt-8 font-bold text-neutral-200">
              {loadingText} {loadingPercentage}%
            </p>
          )}

          {apiError && (
            <p className="mt-5 text-red-400">
              Oops, that didn&apos;t work. {apiError}
            </p>
          )}
        </div>
      </div>
    </>
  )
}
