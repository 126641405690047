import React from 'react'
import { PricingBoxes } from '../../components/PricingBoxes'

export const LifetimePass: React.FC = () => {
  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 xl:w-2/3">
          <p className="text-6xl font-bold text-neutral-200">
            Poki Lifetime Pass
          </p>

          <div className="flex flex-col gap-5 pb-12 mt-12 md:flex-row">
            <div className="w-full md:w-1/2 2xl:w-2/3">
              <video autoPlay muted={true} loop playsInline>
                <source src="/images/poki_lifetime_pass.mp4" type="video/mp4" />
              </video>
            </div>

            <div className="flex flex-col w-full md:w-1/2 2xl:w-full">
              <p className="text-sm text-neutral-200">
                The Poki Lifetime Pass is a limited supply utility token that
                allows you to unlock Poki Pro features.
              </p>

              <div className="flex flex-col h-full mt-6">
                <p className="text-xl font-bold text-neutral-400">
                  Total Supply
                </p>
                <p className="text-sm font-semibold text-white">1000 Passes.</p>

                <p className="mt-6 text-xl font-bold text-neutral-400">
                  Benefits
                </p>
                <p className="text-sm font-semibold text-white">
                  Read below for the full list of current and planned benefits.
                </p>

                <a
                  href="https://www.jpg.store/collection/poki-lifetime-pass"
                  target="_blank"
                  rel="noreferrer"
                  className="self-start py-4 mt-12 text-sm font-semibold text-center bg-yellow-400 rounded-lg hover:bg-yellow-300 px-28">
                  Buy on JPG.store
                </a>
              </div>
            </div>
          </div>

          <PricingBoxes />
        </div>
      </div>
    </>
  )
}
