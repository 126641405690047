import React from 'react'

export const Privacy = () => {
  return (
    <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
      <div className="flex flex-col w-full gap-2 p-10 pt-32 pb-20 lg:w-2/3 3xl:w-1/2 text-neutral-200">
        <strong className="mb-4 text-3xl">Privacy Policy</strong>
        <p>
          The following Privacy Policy constitute a legally binding agreement
          made between You, whether personally or on behalf of an entity
          (&quot;You&quot;) and Poki: Cardano Portfolio Tracker
          (&quot;Poki&quot;, &quot;We&quot;, &quot;Us&quot;, or
          &quot;Our&quot;), concerning Your access (however accessed, whether
          via web, mobile, or otherwise) to and use of the getpoki.com website
          and all the other sub-domains related to getpoki.com
          (&quot;Site&quot;). Your Privacy is important to Us, therefore we
          comply with the applicable data protection laws, including the EU
          General Data Protection Regulation (GDPR) and Switzerland&apos;s
          Federal Act on Data Protection (FADP). BY USING OUR SITE OR OUR
          SERVICES, YOU CONSENT TO THE PRIVACY POLICY.
        </p>
        <strong className="my-4">
          1. What information we collect from you
        </strong>
        <p>
          We collect Your Data through a variety of methods: either You provide
          it to Us directly, we collect it from external sources, or we collect
          it by using automated means.
          <p className="my-2">1.1 Information You give Us</p>
          <p>
            You may give Us information about You, such as Your name and contact
            information, language preference, job title, business affiliations
            and other information, by filling in forms on Our Sites or by
            corresponding with Us by discord, phone, email or otherwise. This
            includes information you provide when you report a problem. We may
            collect Your Data If You register or login to Our Sites using social
            media (e.g., Discord, Google, or Twitter) to authenticate your
            identity and connect your social media login information with us. We
            may process geographical locations. We may also collect data through
            public sources. By linking Your Wallet to Our Site we may collect
            Transaction information. We retain the right to collect from You
            Technical information, such as Your Cardano wallet address and
            network information regarding transactions. In connection to Our
            Services we may collect and maintain information relating to
            transactions you effect using Our Services.
          </p>
          <p className="my-2">1.2 Automatically collected Information</p>
          <p>
            Following information might be automatically collected in order to
            enter the Site or use Our Services: Internet Protocol
            (&quot;IP&quot;) address, date and time of the website call, time
            zone difference to GMT, content of the called website, operating
            system and access status / HTTP-status code, volume of transferred
            data, referrer-URL, information concerning the type, language and
            version of the internet browser used. We only use Your Data where we
            have a legal basis to do so.
          </p>
          <p className="my-2">1.3 Data form third parties</p>
          We may collect personal data from companies that offer their products
          and/or services for use in conjunction with our Service or whose
          products and/or services may be linked from our Service.
          <p className="my-2">
            1.4 Public information Observed from the blockchain
          </p>
          We collect data from the public blockchain such as, but not limited
          to, addresses, NFT sales transfers purchases.
        </p>
        <strong className="my-4">2. How We will use Your Data</strong>
        We collect Your data to:
        <ul className="pl-6 list-disc">
          <li>Provide Our Services (including customer support)</li>
          <li>Personalize Your experience and improve the Service</li>
          <li>Verify your identity</li>
          <li>Process transactions</li>
          <li>Improve, develop and market our Services</li>
          <li>
            Investigate and address conduct that may violate our Terms of
            Service
          </li>
          <li>
            Detect, prevent, and address fraud, violations of our terms or
            policies, and/or other harmful or unlawful activity
          </li>
          <li>Analyze Our Services</li>
          <li>Resolve disputes, collect payments, troubleshoot problems</li>
          <li>
            Communicate with You about Our Services. We also use your Personal
            Data to respond to any questions, comments or requests you filed
            with us and the handling of any complaints
          </li>
          <li>Comply with legal obligations or regulatory requirements</li>
        </ul>
        <strong className="my-4">3. Data Retention</strong>
        <p>
          We will only retain your personal information for as long as necessary
          to fulfil the purposes for which we collected it, including for the
          purposes of satisfying any legal, accounting, or reporting obligations
          or to resolve disputes. When Personal Data is no longer necessary for
          the purpose for which it was collected, We will remove any details
          that identifies You or We will securely destroy the records, where
          permissible.
        </p>
        <strong className="my-4">
          4. Disclosure of Personal Data to third parties
        </strong>
        We may share your information with selected recipients to perform
        functions required to provide certain Services to you and/or in
        connection with Our efforts to prevent and investigate fraudulent or
        other criminal activity. All such third parties will be contractually
        bound to protect data in compliance with our Privacy Policy:
        <ul className="pl-6 list-disc">
          <li>
            Service providers who help with Our business operations and to
            deliver Our Services, such as Cloud service providers providing
            cloud infrastructure
          </li>
          <li>
            Law enforcement, government officials, regulatory agencies, Our
            banks, and other third parties pursuant to a subpoena, court order
            or other legal process; or when We believe, in Our sole discretion,
            that the disclosure of data is necessary to report suspected illegal
            or fraudulent activity or to investigate violations of Our Terms and
            Condition
          </li>
          <li>
            Funding and transaction information related to Your use of certain
            Services may be recorded on a public blockchain (Cardano). Public
            blockchains are distributed ledgers, intended to immutably record
            transactions across wide networks of computer systems. Many
            blockchains are open to forensic analysis which can lead to
            deanonymization and the unintentional revelation of private
            financial information, especially when block chain data is combined
            with other data. Because blockchains are decentralised are not
            controlled or operated by Us, We are not able to erase, modify, or
            alter Personal Data from such networks
          </li>
          <li>
            Our professional advisers including lawyers, auditors and insurers
          </li>
          <li>Marketing service Providers</li>
          <li>
            Third party service providers who help us with client insight
            analytics, such technical infrastructure and Service analysis
          </li>
          <li>
            Afilliates: We may share Your Personal Information with any
            subsidiaries, joint ventures, or other companies under our common
            control, in which case we will require our Affiliates to honor this
            Privacy Policy.
          </li>
        </ul>
        <strong className="my-4">5. Third-Party Websites</strong>
        <p>
          Our Service may contain links to third-party websites. When You click
          on a link to any other website or location, You will leave our Service
          and go to another site, and another entity may collect Personal Data
          from you. We have no control over these third-party websites or their
          content. The terms of this Privacy Policy do not apply to these
          third-party websites or their content. links to third-party do not
          signify our endorsement of such third parties or their products,
          content, or websites.
        </p>
        <strong className="my-4">6. Third-Pary Wallets</strong>
        <p>
          To use some of our Services, you must use a third-party wallet which
          allows you to engage in transactions on public blockchains. Your
          interactions with any third-party wallet provider are governed by the
          applicable terms of service and privacy policy of that third party.
        </p>
        <strong className="my-4">7. What are Your rights?</strong>
        Under the GDPR You have rights related to Your Personal Data:
        <ul className="pl-6 list-disc">
          <li>
            Right to object. You have the right to object to processing of Your
            Personal Data where we are relying on legitimate interests as our
            legal basis. Under certain circumstances, We may have compelling
            legitimate grounds that allow Us to continue processing your
            Personal Data
          </li>
          <li>You have the right to request access to Your Personal Data</li>
          <li>
            You have the Right to request rectification of the Personal Data We
            hold about You
          </li>
          <li>You have the right to request erasure of Your Personal Data</li>
          <li>
            You have the right to request restriction of processing of your
            Personal Data
          </li>
          <li>You have the right to data portability</li>
          <li>
            You have the right to be notified by rectification or erasure of
            Personal Data
          </li>
          <li>Right to submit a complaint with the supervisory authority</li>
          <li>Right to withdraw consent</li>
        </ul>
        <p>
          The exercise of the aforementioned rights is free of charge and can be
          exercised by contacting us at info@cnft-alerts.com.
        </p>
        <strong className="my-4">8. Changes of the privacy policy</strong>
        <p>
          At our discretion, we may change Our privacy policy to reflect updates
          to Our business processes, current acceptable practices, or
          legislative or regulatory changes. If we decide to change this privacy
          policy, we will post the changes here at the same link by which you
          are accessing this Privacy Policy. If required by law, we will get
          your permission or give you the opportunity to opt in to or opt-out
          of, as applicable, any new uses of your personal information.
        </p>
      </div>
    </div>
  )
}
