import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import useApi, { NftEntity, ProjectEntity } from '../../hooks/useApi'
import { useParams } from 'react-router-dom'
import { FaStar, FaRegStar, FaSync } from 'react-icons/fa'
import { IoEarth } from 'react-icons/io5'
import { BounceLoader } from 'react-spinners'
import { useUser } from '../../context/UserContext'
import {
  ADA_SYMBOL,
  formatNumber,
  intToString,
  numberColor,
  numberOfLeadingZeroes,
  numberWithSign,
} from '../../util/format'
import CandlestickChart from '../../components/CandlestickChart'
import { NftDetailsModal } from '../../components/NftDetailsModal'

export const Project: React.FC = () => {
  const [project, setProject] = useState({} as ProjectEntity)
  const [isLoading, setLoading] = useState(true)
  const [holdingsToDisplay, setHoldingsToDisplay] = useState([] as NftEntity[])
  const [holdings, setHoldings] = useState([] as NftEntity[])
  const [listings, setListings] = useState([] as NftEntity[])
  const [viewAll, setViewAll] = useState(false)
  const [starred, setStarred] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [selectedNftIndex, setSelectedNftIndex] = useState(0)
  const [showNftDetailsModal, setShowNftDetailsModal] = useState(false)

  const { id } = useParams()
  const { getProject, addToWatchlist, removeFromWatchlist, syncProject } =
    useApi()
  const { user } = useUser()

  const fetchProject = async () => {
    if (id) {
      const token = user?.token || ''

      try {
        const result = await getProject(id, token)
        if (result.project) {
          setProject(result.project)
          if (result.project.portfolio_holdings?.nfts) {
            setHoldings(result.project.portfolio_holdings.nfts)
            setHoldingsToDisplay(
              result.project.portfolio_holdings.nfts.slice(0, 4),
            )
          }

          if (result.project.listed_nfts) {
            setListings(result.project.listed_nfts.slice(0, 6))
          }

          setStarred(result.project.starred)
          setLoading(false)
        } else {
          console.log('An unknown error occured')
        }
      } catch (error) {
        console.log(error)
        console.log('An unknown error occured')
      }
    }
  }

  useEffect(() => {
    fetchProject()
  }, [])

  useEffect(() => {
    if (project.display_name) {
      document.title = `${project.display_name} / Poki`
    }

    return () => {
      document.title = 'Poki - Cardano Portfolio Tracker'
    }
  }, [project])

  useEffect(() => {
    if (viewAll && project?.portfolio_holdings?.nfts) {
      setHoldingsToDisplay(project.portfolio_holdings.nfts)
    } else if (!viewAll && project?.portfolio_holdings?.nfts) {
      setHoldingsToDisplay(project.portfolio_holdings.nfts.slice(0, 4) || [])
    }
  }, [viewAll])

  useEffect(() => {
    setStarred(project.starred)
  }, [project])

  const fetchAddToWatchlist = async () => {
    if (!user.token) return

    try {
      const { project_id: projectId, error } = await addToWatchlist(
        { project_id: project.id },
        user.token,
      )

      if (projectId) {
        setStarred(true)
      } else if (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchRemoveFromWatchlist = async () => {
    if (!user.token) return

    try {
      const { project_id: projectId, error } = await removeFromWatchlist(
        project.id,
        user.token,
      )

      if (projectId) {
        setStarred(false)
      } else if (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSyncProject = async () => {
    if (!user.token) return
    if (syncing) return

    try {
      setSyncing(true)

      const result = await syncProject(project.id, user.token)
      if (result.project) {
        setProject(result.project)
        if (result.project.portfolio_holdings.nfts) {
          setHoldings(result.project.portfolio_holdings.nfts)
          setHoldingsToDisplay(
            result.project.portfolio_holdings.nfts.slice(0, 4),
          )
        }

        if (result.project.listed_nfts) {
          setListings(result.project.listed_nfts.slice(0, 6))
        }

        setStarred(result.project.starred)
      } else {
        console.log('An unknown error occured')
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSyncing(false)
    }
  }

  const onClickWatchlist = () => {
    starred === true ? fetchRemoveFromWatchlist() : fetchAddToWatchlist()
  }

  const onClickSync = async () => {
    await fetchSyncProject()
  }

  const addFallbackImgSrc = (event: any) => {
    event.target.src = '/images/fallback_img.png'
  }

  const onClickNft = (nft: NftEntity, type: string, index: number) => {
    if (type === 'holding') {
      setSelectedNftIndex(index)
      setShowNftDetailsModal(true)
    } else if (type === 'listing') {
      window.open(`https://jpg.store/asset/${nft.identifier}`, '_blank')
    }
  }

  const renderNft = (nft: NftEntity, index: number, type: string) => {
    return (
      <div
        key={index}
        onClick={() => onClickNft(nft, type, index)}
        className="relative flex flex-col cursor-pointer rounded-xl border-neutral-700 bg-neutral-800 hover:bg-neutral-700">
        {type === 'holding' && nft.listed && (
          <div className="absolute top-0 right-0 flex flex-row items-center justify-between w-full px-4 py-2 border border-yellow-500 rounded-tr-lg rounded-bl-lg md:gap-0 md:w-auto md:flex-col bg-neutral-800">
            <p className="text-xs font-bold text-yellow-500 md:text-sm">
              Listed
            </p>
            <p className="text-xs font-bold text-white md:text-sm">
              {ADA_SYMBOL} {nft.listing_price || 'n/a'}
            </p>
          </div>
        )}
        <LazyLoadImage src={nft.image_url_preview} className="rounded-t-lg" />
        <div className="flex flex-col p-4">
          <p className="font-bold text-white">{nft.display_name || 'n/a'}</p>
          {type === 'holding' && (
            <div className="flex flex-row justify-between w-full">
              <div>
                <p className="mt-1 text-2xl font-bold text-white">
                  {ADA_SYMBOL} {nft.estimated_value || 'n/a'}
                </p>

                <p className="text-sm text-neutral-400">Estimated Value</p>
              </div>
            </div>
          )}

          {type === 'listing' && (
            <div className="flex flex-col">
              <p className="mt-1 text-2xl font-bold text-white">
                {ADA_SYMBOL} {nft.price || 'n/a'}
              </p>

              <p className="text-sm text-neutral-400">JPG.store</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  const onCloseNftDetailsModal = () => {
    setShowNftDetailsModal(false)
  }

  const decimalPlacesCount = () => {
    const leadingZeroesCount = numberOfLeadingZeroes(project.current_price)

    if (leadingZeroesCount === 0 && project.current_price < 1) return 3
    if (project.current_price >= 1) return 2

    return leadingZeroesCount + 3
  }

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 lg:w-2/3 3xl:w-1/2">
          <div className="flex flex-row gap-2 mt-10 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {showNftDetailsModal && (
        <NftDetailsModal
          onClose={onCloseNftDetailsModal}
          nfts={holdings}
          index={selectedNftIndex}
        />
      )}
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <div className="flex flex-row w-full gap-5">
            <img
              src={project.image_url}
              className="object-cover w-32 h-32 rounded-xl"
            />
            <div className="flex flex-col justify-between w-full">
              <div className="flex flex-row justify-between w-full">
                <h1 className="text-5xl font-bold text-white truncate">
                  {project.display_name}
                </h1>

                <div className="flex flex-row justify-end w-1/4 gap-2">
                  {user.token && (
                    <button
                      className="flex flex-row items-center gap-2 px-3 py-2 rounded-lg cursor-pointer bg-neutral-800 hover:bg-neutral-700"
                      onClick={onClickWatchlist}>
                      {starred ? (
                        <div className="flex flex-row items-center justify-center gap-1">
                          <FaStar
                            size={16}
                            color={'#fde047'}
                            className="self-center font-bold text-white"
                          />
                          <p className="self-center text-sm font-semibold text-yellow-400">
                            Starred
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center justify-center gap-1">
                          <FaRegStar
                            size={16}
                            color={'#737373'}
                            className="self-center font-bold text-white"
                          />
                          <p className="self-center text-sm font-semibold text-neutral-500">
                            Star
                          </p>
                        </div>
                      )}
                    </button>
                  )}

                  {project.project_type === 'nft' && (
                    <button
                      className={`flex flex-row items-center gap-2 px-3 py-2 rounded-lg bg-neutral-800
                     ${syncing ? '' : 'hover:bg-neutral-700'}`}
                      onClick={onClickSync}
                      disabled={syncing}>
                      {syncing ? (
                        <div className="flex flex-row items-center justify-center gap-1">
                          <FaSync
                            size={13}
                            color={'#e5e5e5'}
                            className="self-center font-bold text-white animate-spin"
                          />
                          <p className="self-center text-sm font-semibold text-neutral-200">
                            Syncing
                          </p>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center justify-center gap-1">
                          <FaSync
                            size={13}
                            color={'#737373'}
                            className="self-center font-bold text-white"
                          />
                          <p className="self-center text-sm font-semibold text-neutral-500">
                            Sync
                          </p>
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div className="flex flex-row w-full gap-5">
                <div className="flex flex-col items-center justify-center px-6 py-2 border-2 rounded-lg bg-stone-900 border-neutral-800">
                  <p className="text-2xl font-bold text-white">
                    {ADA_SYMBOL}{' '}
                    {formatNumber(project.current_price, decimalPlacesCount())}
                  </p>
                  <p className="text-xs font-bold text-neutral-400">
                    {project.project_type === 'token' ? 'Price' : 'Floor'}
                    <span
                      className={`text-xs font-bold ${numberColor(
                        project.price_change_24h,
                      )}`}>
                      {' '}
                      ({numberWithSign(project.price_change_24h, 2)}%)
                    </span>
                  </p>
                </div>

                <div className="flex flex-col items-center justify-center px-6 py-2 border-2 rounded-lg bg-stone-900 border-neutral-800">
                  <p className="text-2xl font-bold text-white">
                    {ADA_SYMBOL} {intToString(project.market_cap)}
                  </p>
                  <p className="text-xs font-bold text-neutral-400">
                    Market Cap
                  </p>
                </div>

                <div className="flex flex-col items-center justify-center px-6 py-2 border-2 rounded-lg bg-stone-900 border-neutral-800">
                  <p className="text-2xl font-bold text-white">
                    {ADA_SYMBOL} {intToString(project.volume_24h)}
                  </p>
                  <p className="text-xs font-bold text-neutral-400">
                    Volume 24h
                  </p>
                </div>

                {project.project_type === 'token' && (
                  <div className="flex flex-col items-center justify-center px-6 py-2 border-2 rounded-lg bg-stone-900 border-neutral-800">
                    <p className="text-2xl font-bold text-white">
                      {ADA_SYMBOL} {intToString(project.tvl)}
                    </p>
                    <p className="text-xs font-bold text-neutral-400">
                      Liquidity
                    </p>
                  </div>
                )}

                <div className="flex flex-col items-center justify-center px-6 py-2 border-2 rounded-lg bg-stone-900 border-neutral-800">
                  <p className="text-2xl font-bold text-white">
                    {intToString(project.supply)}
                  </p>
                  <p className="text-xs font-bold text-neutral-400">Supply</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-full p-4 mt-6 bg-neutral-800 rounded-xl">
            <CandlestickChart candlestickSeries={project.candlestick_history} />
          </div>

          {project.project_type === 'token' && (
            <div className="flex flex-col w-full">
              {user.token && (
                <div className="flex flex-col mt-12">
                  <h2 className="text-3xl font-bold text-white">
                    Your Holdings
                  </h2>

                  <p className="mt-4 text-2xl font-bold text-white">
                    {ADA_SYMBOL}{' '}
                    {intToString(project.portfolio_holdings.value, true)}
                  </p>
                  <p className="text-sm font-semibold text-neutral-400">
                    {formatNumber(project.portfolio_holdings.amount)} Tokens
                  </p>

                  <div className="flex flex-row gap-2 mt-2">
                    <img
                      src={project.image_url}
                      className="object-cover w-8 h-8 rounded-xl"
                    />
                    <p className="self-center text-sm font-semibold text-white">
                      {project.display_name}
                    </p>
                  </div>
                </div>
              )}

              {project.description && (
                <div className="flex flex-col mt-12">
                  <h2 className="text-3xl font-bold text-white">
                    About {project.display_name}
                  </h2>
                  <p className="mt-4 text-white">{project.description}</p>

                  {project.url && (
                    <div className="flex flex-row items-center gap-2 mt-4">
                      <IoEarth
                        size={25}
                        className="self-center text-blue-400"
                      />

                      <a
                        href={project.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-400 cursor-pointer">
                        {project.url}
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {holdingsToDisplay && holdingsToDisplay.length > 0 && (
            <div className="flex flex-col w-full mt-12">
              <h2 className="text-3xl font-bold text-white">Your Holdings</h2>
              <div className="flex flex-row justify-between w-full">
                <div className="flex flex-row w-full gap-3 mt-4">
                  <p className="self-center text-xl font-bold text-white">
                    {project.portfolio_holdings.amount}{' '}
                    <span className="text-xs text-neutral-400">
                      {holdingsToDisplay.length > 1 ? 'NFTs' : 'NFT'}
                    </span>
                  </p>

                  <p className="self-center text-white">|</p>
                  <p className="self-center text-xl font-bold text-white">
                    {ADA_SYMBOL}{' '}
                    {intToString(project.portfolio_holdings.estimated_value)}{' '}
                    <span className="text-xs text-neutral-400">
                      Estimated Value
                    </span>
                  </p>

                  <p className="self-center text-white">|</p>
                  <p className="self-center text-xl font-bold text-white">
                    {ADA_SYMBOL} {intToString(project.portfolio_holdings.value)}{' '}
                    <span className="text-xs text-neutral-400">
                      Floor Value
                    </span>
                  </p>

                  <p className="self-center text-white">|</p>
                  <p className="self-center text-xl font-bold text-white">
                    {ADA_SYMBOL}{' '}
                    {intToString(project.portfolio_holdings.instant_value)}{' '}
                    <span className="text-xs text-neutral-400">
                      Instant Value
                    </span>
                  </p>
                </div>

                {viewAll && (
                  <p
                    className="mt-4 text-blue-400 cursor-pointer"
                    onClick={() => setViewAll(false)}>
                    Collapse
                  </p>
                )}
              </div>
              <div className="grid w-full grid-cols-4 gap-4 mt-4">
                {holdingsToDisplay.map((nft, index) =>
                  renderNft(nft, index, 'holding'),
                )}
              </div>

              {!viewAll && holdings.length > 4 && (
                <p
                  className="mt-4 text-blue-400 cursor-pointer"
                  onClick={() => setViewAll(true)}>
                  Show all holdings
                </p>
              )}

              {viewAll && (
                <p
                  className="mt-4 text-blue-400 cursor-pointer"
                  onClick={() => setViewAll(false)}>
                  Collapse
                </p>
              )}
            </div>
          )}

          {listings && listings.length > 0 && (
            <div className="flex flex-col w-full mt-12">
              <h2 className="text-3xl font-bold text-white">Listings</h2>

              <div className="grid w-full grid-cols-6 gap-4 mt-4">
                {listings.map((nft, index) => renderNft(nft, index, 'listing'))}
              </div>

              <a
                href={`https://jpg.store/collection/${project.policy}`}
                target="_blank"
                rel="noreferrer"
                className="mt-4 text-blue-400 cursor-pointer">
                Show all listings
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
