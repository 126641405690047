import React, { useEffect, useState } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { useUser } from '../../context/UserContext'
import useApi, { AnalyticsEntity, Collection } from '../../hooks/useApi'
import { BounceLoader } from 'react-spinners'
import {
  formatNumber,
  intToString,
  numberColor,
  numberWithSign,
  ADA_SYMBOL,
  INTERVAL_MAPPING,
} from '../../util/format'
import { useNavigate } from 'react-router-dom'

const INTERVALS = [
  {
    display: '24H',
    key: '24h',
  },
  {
    display: '1W',
    key: '7d',
  },
  {
    display: '1M',
    key: '30d',
  },
  {
    display: '3M',
    key: '90d',
  },
  {
    display: '6M',
    key: '180d',
  },
]

const PIE_COLORS = [
  '#7209B7',
  '#560BAD',
  '#3A0CA3',
  '#3F37C9',
  '#4361EE',
  '#4895EF',
  '#4CC9F0',
]

const ASSET_CATEGORIES = [
  {
    key: 'combined',
    name: 'Combined',
  },
  {
    key: 'nfts',
    name: 'NFTs',
  },
  {
    key: 'tokens',
    name: 'Tokens',
  },
]

export const Analytics: React.FC = () => {
  const [analytics, setAnalytics] = useState({} as AnalyticsEntity)
  const [isLoading, setLoading] = useState(true)
  const [selectedInterval, setSelectedInterval] = useState('24h')
  const [analyticsLoading, setAnalyticsLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(
    ASSET_CATEGORIES[0].key,
  )

  const { getAnalytics } = useApi()
  const { user } = useUser()
  const navigate = useNavigate()

  const fetchAnalytics = async (interval: string) => {
    if (user.id && user.token) {
      try {
        const { analytics } = await getAnalytics(interval, user.token)

        if (analytics) {
          setAnalytics(analytics)
        } else {
          console.log('An unknown error occured')
        }
      } catch (error) {
        console.log('An unknown error occured')
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchAnalytics(selectedInterval)
  }, [user])

  const onClickCollection = (collection: Collection) => {
    navigate(`/projects/${collection.slug}`)
  }

  const onClickAssetCategory = (key: string) => {
    setSelectedCategory(key)
  }

  const mapScore = (score: number) => {
    if (score < 0.25) {
      return 'Low'
    } else if (score >= 0.25 && score < 0.5) {
      return 'Medium'
    } else if (score >= 0.5 && score < 0.75) {
      return 'High'
    } else {
      return 'Very High'
    }
  }

  const mapVolatilityScore = (score: number) => {
    if (score < 0.25) {
      return 'Low'
    } else if (score >= 0.25 && score < 0.5) {
      return 'Medium'
    } else if (score >= 0.5 && score < 0.75) {
      return 'High'
    } else {
      return 'Very High'
    }
  }

  const mapDiversification = (score: number) => {
    if (score < 3) {
      return 'Low'
    } else if (score >= 3 && score < 6) {
      return 'Medium'
    } else if (score >= 6 && score < 8) {
      return 'High'
    } else {
      return 'Very High'
    }
  }

  const mapDiversificationColor = (score: number) => {
    if (score < 3) {
      return 'bg-red-600'
    } else if (score >= 3 && score < 6) {
      return 'bg-orange-600'
    } else if (score >= 6 && score < 8) {
      return 'bg-blue-600'
    } else {
      return 'bg-green-600'
    }
  }

  const mapScoreColor = (score: number) => {
    if (score < 0.25) {
      return 'bg-red-600'
    } else if (score >= 0.25 && score < 0.5) {
      return 'bg-orange-600'
    } else if (score >= 0.5 && score < 0.75) {
      return 'bg-blue-600'
    } else {
      return 'bg-green-600'
    }
  }

  const mapVolatilityScoreColor = (score: number) => {
    if (score < 0.25) {
      return 'bg-green-600'
    } else if (score >= 0.25 && score < 0.5) {
      return 'bg-blue-600'
    } else if (score >= 0.5 && score < 0.75) {
      return 'bg-orange-600'
    } else {
      return 'bg-red-600'
    }
  }

  const intervalFormatted = () => INTERVAL_MAPPING[selectedInterval]

  const onIntervalClicked = async (interval: string) => {
    setSelectedInterval(interval)
    setAnalyticsLoading(true)
    await fetchAnalytics(interval)
    setAnalyticsLoading(false)
  }

  const truncateString = (value: string, limit: number) => {
    if (value.length > limit) {
      return `${value.substring(0, limit)}...`
    } else {
      return value
    }
  }

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">Insights</p>

          <div className="flex flex-row gap-2 mt-12 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">Insights</p>

          <div className="flex flex-row justify-between mt-12">
            <div className="flex flex-row self-center gap-4">
              {ASSET_CATEGORIES.map((categoryData, index) => {
                return (
                  <div
                    className="flex flex-row gap-4"
                    key={`asset-category-${index}`}>
                    <p
                      className={`self-center mt-4 font-bold cursor-pointer
                   ${
                     categoryData.key === selectedCategory
                       ? 'text-neutral-200'
                       : 'text-neutral-400'
                   }`}
                      onClick={() => onClickAssetCategory(categoryData.key)}>
                      {categoryData.name}
                    </p>
                    {index < ASSET_CATEGORIES.length - 1 && (
                      <p className="self-center mt-4 font-bold text-neutral-200">
                        |
                      </p>
                    )}
                  </div>
                )
              })}
            </div>

            <div className="flex flex-row self-center gap-4">
              {INTERVALS.map((intervalData, index) => {
                return (
                  <p
                    key={`total-interval-${index}`}
                    className={`px-4 py-1 text-xs font-semibold
                            bg-gray-700 rounded-lg cursor-pointer
                            hover:bg-gray-500 text-neutral-200
                            ${
                              intervalData.key === selectedInterval
                                ? 'bg-gray-500'
                                : ''
                            }`}
                    onClick={() => onIntervalClicked(intervalData.key)}>
                    {intervalData.display}
                  </p>
                )
              })}
            </div>
          </div>

          <div className="grid w-full grid-cols-1 gap-8 mt-4 md:grid-cols-2">
            <div className="px-5 pt-3 pb-4 border border-stone-800 bg-stone-900 rounded-xl">
              <p className="font-bold text-neutral-400">
                Your Biggest Gainers ({intervalFormatted()})
              </p>

              {analyticsLoading ? (
                <div className="flex flex-row self-center justify-center gap-2 my-20">
                  <BounceLoader
                    className="self-center"
                    color={'#ffffff'}
                    loading={true}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />

                  <p className="self-center text-lg font-bold text-neutral-400">
                    Loading
                  </p>
                </div>
              ) : (
                <table className="w-full mt-3 text-xs table-auto">
                  <tbody>
                    {(
                      analytics[
                        `biggest_gainers_${selectedCategory}`
                      ] as Collection[]
                    ).map((collection, index) => {
                      return (
                        <tr key={index} className="font-bold text-neutral-200">
                          <td
                            className="flex flex-row gap-2 py-2 hover:cursor-pointer"
                            onClick={() => onClickCollection(collection)}>
                            <LazyLoadImage
                              src={collection.image}
                              className="h-5"
                            />
                            <p className="self-center truncate">
                              {truncateString(collection.name, 20)}
                            </p>
                          </td>
                          <td
                            className={`text-right ${numberColor(
                              collection.change_total,
                            )}`}>
                            {ADA_SYMBOL}{' '}
                            {numberWithSign(collection.change_total)}
                          </td>
                          <td
                            className={`text-right ${numberColor(
                              collection.change_percentage,
                            )}`}>
                            {numberWithSign(collection.change_percentage)}%
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>

            <div className="px-5 pt-3 pb-4 border border-stone-800 bg-stone-900 rounded-xl">
              <p className="font-bold text-neutral-400">
                Your Biggest Decliners ({intervalFormatted()})
              </p>

              {analyticsLoading ? (
                <div className="flex flex-row self-center justify-center gap-2 my-20">
                  <BounceLoader
                    className="self-center"
                    color={'#ffffff'}
                    loading={true}
                    size={20}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />

                  <p className="self-center text-lg font-bold text-neutral-400">
                    Loading
                  </p>
                </div>
              ) : (
                <table className="w-full mt-3 text-xs table-auto">
                  <tbody>
                    {(
                      analytics[
                        `biggest_decliners_${selectedCategory}`
                      ] as Collection[]
                    ).map((collection, index) => {
                      return (
                        <tr key={index} className="font-bold text-neutral-200">
                          <td
                            className="flex flex-row gap-2 py-2 hover:cursor-pointer"
                            onClick={() => onClickCollection(collection)}>
                            <LazyLoadImage
                              src={collection.image}
                              className="h-5"
                            />
                            <p className="self-center truncate">
                              {truncateString(collection.name, 20)}
                            </p>
                          </td>
                          <td
                            className={`text-right py-2 ${numberColor(
                              collection.change_total,
                            )}`}>
                            {ADA_SYMBOL}{' '}
                            {numberWithSign(collection.change_total)}
                          </td>
                          <td
                            className={`text-right ${numberColor(
                              collection.change_percentage,
                            )}`}>
                            {numberWithSign(collection.change_percentage)}%
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <div className="flex flex-col">
              <p className="mt-12 text-2xl font-bold tracking-wide text-neutral-200">
                NFT Shares
              </p>
              <div className="justify-start w-full p-4 mt-4 border rounded-lg h-96 border-neutral-800">
                <ResponsivePie
                  data={analytics.share_nfts}
                  margin={{ left: 10, right: 10, bottom: 50, top: 50 }}
                  sortByValue={false}
                  valueFormat=" >-.0%"
                  innerRadius={0.4}
                  padAngle={2}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  colors={PIE_COLORS}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#f9fafb"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor="#e5e5e5"
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: -20,
                      translateY: 0,
                      itemsSpacing: 10,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#fff',
                          },
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <p className="mt-12 text-2xl font-bold tracking-wide text-neutral-200">
                Token Shares
              </p>
              <div className="justify-start w-full p-4 mt-4 border rounded-lg h-96 border-neutral-800">
                <ResponsivePie
                  data={analytics.share_tokens}
                  margin={{ left: 10, right: 10, bottom: 50, top: 50 }}
                  sortByValue={false}
                  valueFormat=" >-.0%"
                  innerRadius={0.4}
                  padAngle={2}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  colors={PIE_COLORS}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#f9fafb"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor="#e5e5e5"
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: -20,
                      translateY: 0,
                      itemsSpacing: 10,
                      itemWidth: 100,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      itemDirection: 'left-to-right',
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#fff',
                          },
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <p className="text-2xl font-bold tracking-wide mt-14 text-neutral-200">
            NFT Portfolio Scoreboard 🎯
          </p>

          <div className="grid grid-cols-3 gap-4 mt-4">
            <div className="flex flex-col p-5 border rounded-lg border-neutral-800">
              <div className="flex flex-row justify-between">
                <p className="self-center text-base font-bold tracking-wide text-neutral-400">
                  Ease of Liquidation
                </p>
                <p
                  className={`self-center px-2 py-1 text-xs font-semibold text-white rounded-lg ${mapScoreColor(
                    analytics.liquidity_score,
                  )}`}>
                  {mapScore(analytics.liquidity_score)}
                </p>
              </div>
              <p className="mt-4 text-sm text-neutral-200">
                <strong>
                  {formatNumber(analytics.liquidity_score * 100)}%
                </strong>{' '}
                of the collections you are invested in had sales in the past 7
                days.
              </p>
            </div>

            <div className="flex flex-col p-5 border rounded-lg border-neutral-800">
              <div className="flex flex-row justify-between">
                <p className="self-center text-base font-bold tracking-wide text-neutral-400">
                  Volatility
                </p>
                <p
                  className={`self-center px-2 py-1 text-xs font-semibold text-white ${mapVolatilityScoreColor(
                    analytics.volatility_score,
                  )} rounded-lg`}>
                  {mapVolatilityScore(analytics.volatility_score)}
                </p>
              </div>
              <p className="mt-4 text-sm text-neutral-200">
                <strong>
                  {formatNumber(analytics.volatility_score * 100)}%
                </strong>{' '}
                of the collections you are invested in fluctuated more than 20%
                in their floor price in the past 7 days.
              </p>
            </div>

            <div className="flex flex-col p-5 border rounded-lg border-neutral-800">
              <div className="flex flex-row justify-between">
                <p className="self-center text-base font-bold tracking-wide text-neutral-400">
                  Diversification
                </p>
                <p
                  className={`self-center px-2 py-1 text-xs font-semibold text-white ${mapDiversificationColor(
                    analytics.diversification_score,
                  )} rounded-lg`}>
                  {mapDiversification(analytics.diversification_score)}
                </p>
              </div>
              <p className="mt-4 text-sm text-neutral-200">
                <strong>{analytics.diversification_score}</strong> of the
                collections you are invested in make up for more than 80% of
                your total NFT portfolio value.
              </p>
            </div>
          </div>

          <p className="mt-20 text-2xl font-bold tracking-wide text-neutral-200 border-neutral-800">
            Trending NFT Collections 🔥
          </p>

          {analytics.trending_collections.length === 0 ? (
            <p className="mt-8 text-sm text-neutral-200">
              Currently there are no trending collections in your portfolio.
            </p>
          ) : (
            <div className="flex flex-col mt-8">
              <p className="text-sm text-neutral-200">
                The following collections from your portfolio had a significant
                amount of sales or volume within the last 7 days.
              </p>
              <div className="flex flex-col p-3 px-5 pb-5 mt-4 border rounded-lg bg-stone-900 border-stone-800">
                {analytics.trending_collections.map((collection, index) => {
                  return (
                    <div
                      className="flex flex-row justify-between py-1"
                      key={`changes-${index}`}
                      onClick={() => navigate(`/projects/${collection.slug}`)}>
                      <div className="flex flex-row items-center w-1/2">
                        {' '}
                        <LazyLoadImage
                          className="h-5 rounded-lg cursor-pointer"
                          src={collection.image}
                        />
                        <p className="ml-2 text-xs font-bold text-white cursor-pointer">
                          {collection.name}
                        </p>
                      </div>
                      <div className="flex flex-col w-1/4">
                        <p className="text-xs font-bold text-right text-white">
                          {intToString(collection.sales_count as number)} Sales
                        </p>
                        <p className="text-xs text-right text-green-400">
                          {ADA_SYMBOL}{' '}
                          {intToString(collection.sales_volume as number)}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          <p className="mt-20 text-2xl font-bold tracking-wide text-neutral-200 border-neutral-800">
            Zombie NFT Collections 💀
          </p>

          {analytics.zombie_collections.length === 0 ? (
            <p className="mt-8 text-sm text-neutral-200">
              Currently there are no zombie collections in your portfolio.
            </p>
          ) : (
            <div className="flex flex-col mt-8">
              <p className="text-sm text-neutral-200">
                The following collections from your portfolio didn&apos;t have
                any sales within the past 7 days.
              </p>

              <div className="flex flex-col p-3 px-5 pb-5 mt-4 border bg-stone-900 border-stone-800 rounded-xl">
                <div>
                  {analytics.zombie_collections.map((collection, index) => {
                    return (
                      <div
                        className="flex flex-row justify-between py-1"
                        key={`changes-${index}`}
                        onClick={() =>
                          navigate(`/projects/${collection.slug}`)
                        }>
                        <div className="flex flex-row items-center w-1/2">
                          {' '}
                          <LazyLoadImage
                            className="h-5 cursor-pointer"
                            src={collection.image}
                          />
                          <p className="ml-2 text-xs font-bold text-white cursor-pointer">
                            {collection.name}
                          </p>
                        </div>

                        <div className="flex flex-col w-1/4">
                          <p className="text-xs font-bold text-right text-white">
                            Last Sale
                          </p>
                          <p className="text-xs text-right text-white">
                            {collection.last_sale}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
