/* eslint-disable camelcase */

export interface User {
  token?: string
  id?: number
  pro?: boolean
  stake_addr?: string
  accounts?: Account[]
  discord_id?: string
  app_key?: string
  notification_config?: NotificationConfig
}

export interface NotificationConfig {
  txes_discord: boolean
}

export interface NotificationConfigResponse {
  notification_config: NotificationConfig
  error?: string
}

export interface Account {
  id: number
  stake_addr: string
  name: string
  node_activated: boolean
}

export interface UserResponse {
  user?: User
  error?: string
}

export interface WalletResponse {
  accounts?: Account[]
  error?: string
}

export interface AddNftCollectionResponse {
  collection?: {
    id: number
  }
  error?: string
}

export interface WlAddressResponse {
  stake_addr?: string
  message?: string
  error?: string
}

export interface ChangeCollection {
  total: number
  percentage: number
}

export interface Change {
  [key: string]: number
  total_combined: number
  percentage_combined: number
  total_ada: number
  percentage_ada: number
  total_nfts: number
  percentage_nfts: number
}

export interface Share {
  id: string
  label: string
  value: string
}

export interface AnalyticsEntity {
  [key: string]: Collection[] | number | Share[]
  biggest_gainers: Collection[]
  biggest_decliners: Collection[]
  biggest_gainers_tokens: Collection[]
  biggest_decliners_tokens: Collection[]
  biggest_gainers_combined: Collection[]
  biggest_decliners_combined: Collection[]
  zombie_collections: Collection[]
  trending_collections: Collection[]
  diversification_score: number
  liquidity_score: number
  volatility_score: number
  share_tokens: Share[]
  share_nfts: Share[]
}

export interface AnalyticsResponse {
  analytics?: AnalyticsEntity
  error?: string
}

export interface Collection {
  [key: string]: string | number
  name: string
  id: number
  image: string
  count: number
  price: number
  value: number
  jpg_store_name: string
  change_total: number
  change_percentage: number
  share: number
  share_category: number
  type: string
  floor_thickness: number
  collection_offer: number | string
  slug: string
}

export interface HistoryEntity {
  [key: string]: number | string
  key: string
  value_total: number
  value_ada: number
  value_nfts: number
}

export interface CollectionHistoryEntity {
  [key: string]: number | string
  key: string
  value: number
}

export interface PortfolioEntity {
  [key: string]:
    | HistoryEntity[]
    | Collection[]
    | number
    | boolean
    | Change
    | string[]
  history: HistoryEntity[]
  sync_progress: number
  value_total: number
  value_total_usd: number
  value_ada: number
  value_nfts: number
  value_coins: number
  value_staked: number
  value_jpg_listings: number
  value_pond_loans: number
  value_nfts_instant: number
  value_nfts_instant_usd: number
  value_nfts_estimated: number
  value_nfts_estimated_usd: number
  pond_loans_profit: number
  jpg_listings_count: number
  staked_count: number
  change: Change
  tokens: Collection[]
  collections: Collection[]
  history_synced: boolean
  biggest_gainers: Collection[]
  biggest_decliners: Collection[]
  ada_usd_price: number
}

export interface PortfolioResponse {
  portfolio?: PortfolioEntity
  error?: string
}

export interface HoldingsEntity {
  value: number
  count: number
  share: number
  others: number
}

export interface MarkerEntity {
  axis: 'x' | 'y'
  value: string
}

export interface CollectionStats {
  floor: number
  change: ChangeCollection
  history: CollectionHistoryEntity[]
  holdings: HoldingsEntity
  sales_count: number
  sales_volume: number
  unique_buyers: number
  alerts: AlertEntity[]
  markers: MarkerEntity[]
}

export interface TokenStats {
  price: number
  change: ChangeCollection
  history: CollectionHistoryEntity[]
}

export interface CollectionCombined {
  name: string
  jpg_store_name: string
  image: string
  stats: CollectionStats
}

export interface OrderEntity {
  slippage: number
  price: number
  amount: number
  action: string
  tx_time: string
  tx_hash: string
  market_price_per_token: number
  asked_price_per_token: number
}

export interface BuyVsSellVolumeEntity {
  buy: number
  sell: number
}

export interface TokenEntity {
  id: number
  name: string
  policy: string
  display_name: string
  image: string
  stats: TokenStats
  orders: OrderEntity[]
  buying_orders_percentage: number
  selling_orders_percentage: number
  buying_orders_volume: number
  selling_orders_volume: number
}

export interface CollectionResponse {
  collection?: CollectionCombined
  error?: string
}

export interface TokenResponse {
  token?: TokenEntity
  error?: string
}

export interface MetadataEntity {
  floor_change: number
  price: number
  sales_count: number
  value: number
}

export interface AlertEntity {
  live_floor: number
  id: number
  metadata: MetadataEntity
  current_floor: number
  created_at: string
  alert_type: string
  project: Collection
  image: string
}

export interface UpdateWatchlistResponse {
  project_id?: number
  error?: string
}

export interface ProjectResponse {
  project?: ProjectEntity
  error?: string
}

export interface Trait {
  name: string
  value: string
  current_price: number
}

export interface NftEntity {
  id: number
  display_name: string
  identifier: string
  image_url: string
  image_url_preview: string
  price: number
  trait_value: number
  estimated_value: number
  traits: Trait[]
  floor_value: number
  instant_value: number
  project_name: string
  jpg_link: string
  listed: boolean
  listing_price: number
}

export interface PortfolioHoldings {
  [key: string]: number | NftEntity[] | undefined
  amount: number
  value: number
  trait_value: number
  instant_value: number
  estimated_value: number
  nfts?: NftEntity[] | undefined
}

export interface ProjectHistoryEntity {
  value: number
  date_and_time: string
}

export interface CandlestickEntity {
  [key: string]: number | string
  open: number
  close: number
  high: number
  low: number
  time: string
}

export interface ProjectEntity {
  [key: string]:
    | string
    | number
    | undefined
    | ProjectHistoryEntity[]
    | CandlestickEntity[]
    | NftEntity[]
    | PortfolioHoldings
    | boolean
  name: string
  display_name: string
  id: number
  url: string
  image_url: string
  current_price: number
  volume_24h: number
  market_cap: number
  tvl: number
  rank: number
  price_change_24h: number
  project_type: string
  price_change_percentage: number
  price_change_percentage_24h: number
  price_change: number
  supply: number
  description: string
  portfolio_holdings: PortfolioHoldings
  starred: boolean
  history: ProjectHistoryEntity[]
  candlestick_history: CandlestickEntity[]
  listed_nfts: NftEntity[]
  policy: string
}

export interface PulseEntity extends ProjectEntity {
  tx_count: number
  volume: number
  unique_addr_count: number
  buying_orders_percentage?: number
  selling_orders_percentage?: number
}

export interface PulseResponse {
  tokens?: PulseEntity[]
  nfts?: PulseEntity[]
  tokens_market_sentiment?: string
  tokens_volume_trend?: string
  nfts_market_sentiment?: string
  nfts_volume_trend?: string
  error?: string
}

export interface MarketAssetResponse {
  market_assets?: ProjectEntity[]
  top_movers?: ProjectEntity[]
  recently_added?: ProjectEntity[]
  error?: string
}

export interface TopSellingEntity extends Collection {
  volume: number
  sales_count: number
  unique_buyers_count: number
  current_floor: number
}

export interface TokenOrdersEntity extends TokenEntity {
  volume: number
  orders_count: number
  current_price: number
  buying_orders_volume: number
  selling_orders_volume: number
  buying_orders_percentage: number
  selling_orders_percentage: number
}

type Trend = 'very_bad' | 'bad' | 'neutral' | 'good' | 'very_good'

export interface CnftAlertsResponse {
  cnft_alerts: AlertEntity[]
  cnft_sales: TopSellingEntity[]
  cnft_sales_volume: TopSellingEntity[]
  volume_trend: Trend
  market_sentiment: Trend
  token_orders: TokenOrdersEntity[]
}

export interface UserNftProjectsResponse {
  projects?: ProjectEntity[]
  hidden_projects?: ProjectEntity[]
  error?: string
}

export const API_BASE_URL = process.env.REACT_APP_API_URL

export default function useApi() {
  const requestWithBody = async (
    method: string,
    path: string,
    data: object = {},
    authToken = '',
  ) => {
    const res = await fetch(`${API_BASE_URL}${path}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
      body: JSON.stringify(data),
    })

    return res.json()
  }

  const requestWithParams = async (
    method: string,
    path: string,
    params: string,
    authToken = '',
  ) => {
    const res = await fetch(`${API_BASE_URL}${path}${params}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(authToken && { Authorization: `Bearer ${authToken}` }),
      },
    })

    return res.json()
  }

  const getOrCreateUser = (address: string): Promise<UserResponse> =>
    requestWithBody('POST', '/api/v1/poki/users/with_address', {
      wallet_address: address,
    })

  const getUser = (id: number, token: string): Promise<UserResponse> =>
    requestWithParams('GET', `/api/v1/poki/users/${id}`, '', token)

  const addWallet = (
    address: string,
    name: string,
    token: string,
  ): Promise<WalletResponse> =>
    requestWithBody(
      'POST',
      '/api/v1/poki/wallets',
      {
        wallet_address: address,
        wallet_name: name,
      },
      token,
    )

  const updateWallet = (
    id: number,
    node_activated: boolean,
    token: string,
  ): Promise<WalletResponse> =>
    requestWithBody(
      'PUT',
      `/api/v1/poki/wallets/${id}`,
      {
        node_activated: node_activated,
      },
      token,
    )

  const addNftCollection = (
    policy: string,
    token: string,
  ): Promise<AddNftCollectionResponse> =>
    requestWithBody(
      'POST',
      '/api/v1/poki/collections',
      {
        policy: policy,
      },
      token,
    )

  const showWlAddress = (address: string): Promise<WlAddressResponse> =>
    requestWithParams('GET', `/api/v1/poki/wl_addresses/${address}`, '', '')

  const deleteWallet = (id: number, token: string): Promise<WalletResponse> =>
    requestWithParams('DELETE', `/api/v1/poki/wallets/${id}`, '', token)

  const getPortfolio = (
    interval: string,
    token: string,
  ): Promise<PortfolioResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/portfolios`,
      `?interval=${interval}`,
      token,
    )

  const getAnalytics = (
    interval: string,
    token: string,
  ): Promise<AnalyticsResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/portfolios/analytics`,
      `?interval=${interval}`,
      token,
    )

  const getProject = (id: string, token: string): Promise<ProjectResponse> =>
    requestWithParams('GET', `/api/v1/poki/projects/${id}`, '', token)

  const getCollection = (
    id: string,
    interval: string,
  ): Promise<CollectionResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/collections/${id}`,
      `?interval=${interval}`,
      '',
    )

  const getToken = (id: string, interval: string): Promise<TokenResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/tokens/${id}`,
      `?interval=${interval}`,
      '',
    )

  const getCnftAlerts = (): Promise<CnftAlertsResponse> =>
    requestWithParams('GET', `/api/v1/cnft_alerts`, '', '')

  const updateUser = (
    userId: number,
    discordId: string,
    token: string,
  ): Promise<UserResponse> =>
    requestWithBody(
      'PUT',
      `/api/v1/poki/users/${userId}`,
      {
        discord_id: discordId,
      },
      token,
    )

  const updateNotificationConfig = (
    data: {
      txes_discord: boolean
    },
    token: string,
  ): Promise<NotificationConfigResponse> =>
    requestWithBody('POST', `/api/v1/poki/notification_configs`, data, token)
  const getPulse = (): Promise<PulseResponse> =>
    requestWithParams('GET', `/api/v1/poki/pulse`, '', '')

  const addToWatchlist = (
    data: {
      project_id: number
    },
    token: string,
  ): Promise<UpdateWatchlistResponse> =>
    requestWithBody('POST', `/api/v1/poki/watchlist_projects`, data, token)

  const removeFromWatchlist = (
    id: number,
    token: string,
  ): Promise<UpdateWatchlistResponse> =>
    requestWithBody(
      'DELETE',
      `/api/v1/poki/watchlist_projects/${id}`,
      {},
      token,
    )

  const syncProject = (
    project_id: number,
    token: string,
  ): Promise<ProjectResponse> =>
    requestWithBody(
      'POST',
      `/api/v1/poki/projects/${project_id}/sync`,
      {},
      token,
    )

  const getMarketSearch = (query: string): Promise<MarketAssetResponse> =>
    requestWithParams(
      'GET',
      `/api/v1/poki/markets/search?query=${query}`,
      '',
      '',
    )

  const getUserNftProjects = (
    token: string,
  ): Promise<UserNftProjectsResponse> =>
    requestWithParams('GET', `/api/v1/poki/user_nft_projects`, '', token)

  const hideNftProject = (
    project_id: number,
    token: string,
  ): Promise<UserNftProjectsResponse> =>
    requestWithBody(
      'POST',
      `/api/v1/poki/user_nft_projects/hide`,
      { project_id },
      token,
    )

  const unhideNftProject = (
    project_id: number,
    token: string,
  ): Promise<UserNftProjectsResponse> =>
    requestWithBody(
      'POST',
      `/api/v1/poki/user_nft_projects/unhide`,
      { project_id },
      token,
    )

  return {
    getOrCreateUser,
    getUser,
    addWallet,
    deleteWallet,
    getPortfolio,
    getCollection,
    getCnftAlerts,
    updateUser,
    getAnalytics,
    getToken,
    showWlAddress,
    addNftCollection,
    updateWallet,
    updateNotificationConfig,
    getProject,
    getPulse,
    addToWatchlist,
    removeFromWatchlist,
    syncProject,
    getMarketSearch,
    getUserNftProjects,
    hideNftProject,
    unhideNftProject,
  }
}
