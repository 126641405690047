import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useUser } from '../../context/UserContext'
import useApi, { Collection, PortfolioEntity, User } from '../../hooks/useApi'
import { BounceLoader } from 'react-spinners'

import {
  IoCaretDown,
  IoCaretUp,
  IoInformationCircleOutline,
} from 'react-icons/io5'
import {
  formatNumber,
  intToString,
  numberColor,
  isPositiveDiff,
  numberWithSign,
  ADA_SYMBOL,
  FREE_USER_HISTORY,
  formatDate,
  percentageDiff,
  INTERVALS,
  USD_SYMBOL,
} from '../../util/format'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from '../../route-paths'
import { LineChart } from '../../components/LineChart'

interface SortEntity {
  [key: string]: string
  value: string
  name: string
  price: string
  asset_count: string
}

interface TotalDataEntity {
  key: string
  keyUsd: string
  name: string
  countKey: string
  countText: string
  countKeyUsd: string
  comingSoon?: boolean
  countFormat?: (value: number) => string
}

type ValueFromChart = number | null
type DateFromChart = string | null
type AdaUsd = number | null

interface TotalsInWalletEntity {
  key: string
  keyUsd: string
  name: string
  keyChange: string
  keyChangePercentage: string
  keyChangeUsd: string
  keyChangePercentageUsd: string
  display?: boolean
  proRequired?: boolean
  assetCategory: string
}

const TOTALS_IN_WALLET = [
  {
    key: 'value_total',
    keyUsd: 'value_total_usd',
    name: 'Total',
    keyChange: 'total_combined',
    keyChangePercentage: 'percentage_combined',
    keyChangeUsd: 'total_combined_usd',
    keyChangePercentageUsd: 'percentage_combined_usd',
    assetCategory: 'combined',
  },
  {
    key: 'value_nfts',
    name: 'NFTs',
    keyChange: 'total_nfts',
    keyUsd: 'value_nfts_usd',
    keyChangePercentage: 'percentage_nfts',
    keyChangeUsd: 'total_nfts_usd',
    keyChangePercentageUsd: 'percentage_nfts_usd',
    display: true,
    assetCategory: 'nfts',
  },
  {
    key: 'value_coins',
    name: 'Tokens',
    keyChange: 'total_coins',
    keyUsd: 'value_coins_usd',
    keyChangePercentage: 'percentage_coins',
    keyChangeUsd: 'total_coins_usd',
    keyChangePercentageUsd: 'percentage_coins_usd',
    display: true,
    proRequired: false,
    assetCategory: 'tokens',
  },
  {
    key: 'value_ada',
    name: 'ADA',
    keyUsd: 'value_ada_usd',
    keyChange: 'total_ada',
    keyChangePercentage: 'percentage_ada',
    keyChangeUsd: 'total_ada_usd',
    keyChangePercentageUsd: 'percentage_ada_usd',
    display: true,
    assetCategory: 'combined',
  },
]

const TOTALS_IN_SMART_CONTRACTS = [
  {
    key: 'value_jpg_listings',
    keyUsd: 'value_jpg_listings_usd',
    countKeyUsd: '_',
    name: 'JPG.store',
    countKey: 'jpg_listings_count',
    countText: 'Listed NFTs',
  },
  {
    key: 'value_staked',
    keyUsd: 'value_staked_usd',
    countKeyUsd: '_',
    name: 'Staked NFTs',
    comingSoon: false,
    countKey: 'staked_count',
    countText: 'Staked NFTs',
  },
  {
    key: 'value_pond_loans',
    keyUsd: 'value_pond_loans_usd',
    name: 'Pond Loans',
    countKey: 'pond_loans_profit',
    countKeyUsd: 'pond_loans_profit_usd',
    countText: 'Profit',
    comingSoon: false,
    countFormat: intToString,
  },
]

const ASSET_CATEGORIES = [
  {
    key: 'combined',
    name: 'Combined',
  },
  {
    key: 'nfts',
    name: 'NFTs',
  },
  {
    key: 'tokens',
    name: 'Tokens',
  },
]

export const Portfolio: React.FC = () => {
  const [portfolio, setPortfolio] = useState({} as PortfolioEntity)
  const [isLoading, setLoading] = useState(true)
  const [tableData, setTableData] = useState([] as Collection[])
  const [tableDataSorted, setTableDataSorted] = useState([] as Collection[])
  const [lastSort, setLastSort] = useState({ value: 'normal' } as SortEntity)
  const [selectedInterval, setSelectedInterval] = useState('24h')
  const [historyLoading, setHistoryLoading] = useState(false)
  const [selectedSeries, setSelectedSeries] = useState<TotalsInWalletEntity>(
    TOTALS_IN_WALLET[0],
  )
  const [valueFromChart, setValueFromChart] = useState(null as ValueFromChart)
  const [dateFromChart, setDateFromChart] = useState(null as DateFromChart)
  const [selectedCategory, setSelectedCategory] = useState(
    ASSET_CATEGORIES[0].key,
  )
  const [showValueTooltip, setShowValueTooltip] = useState(false)
  const [_user, setUser] = useState({} as User)
  const [fiatModeChecked, setFiatModeChecked] = useState(false)
  const [hideBalancesChecked, setHideBalancesChecked] = useState(false)
  const [adaUsd, setAdaUsd] = useState(null as AdaUsd)

  const { getPortfolio, getUser } = useApi()
  const { user } = useUser()
  const navigate = useNavigate()

  const fetchPortfolio = async (interval: string, category: string) => {
    if (_user.id && _user.token) {
      try {
        const { portfolio: portfolioFromResponse } = await getPortfolio(
          interval,
          _user.token,
        )

        if (portfolioFromResponse) {
          setPortfolio(portfolioFromResponse)
          setAdaUsd(portfolioFromResponse.ada_usd_price)
          if (category === 'nfts') {
            setTableData(portfolioFromResponse.collections)
          } else if (category === 'tokens') {
            setTableData(portfolioFromResponse.tokens)
          } else if (category === 'combined') {
            setTableData(
              portfolioFromResponse.tokens.concat(
                portfolioFromResponse.collections,
              ),
            )
          }
        } else {
          console.log('An unknown error occured')
        }
      } catch (error) {
        console.log('An unknown error occured')
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const fetchUser = async () => {
      if (user.id && user.token) {
        try {
          const { user: userFromResponse } = await getUser(user.id, user.token)

          if (userFromResponse) {
            setUser(userFromResponse)
          } else {
            console.log('An unknown error occured')
          }
        } catch (error) {
          console.log('An unknown error occured')
        }
      }
    }

    fetchUser()
  }, [])

  useEffect(() => {
    const fiatMode = localStorage.getItem('fiatMode')
    const hideBalances = localStorage.getItem('hideBalances')

    if (fiatMode) {
      setFiatModeChecked(JSON.parse(fiatMode))
    }

    if (hideBalances) {
      setHideBalancesChecked(JSON.parse(hideBalances))
    }
  }, [])

  useEffect(() => {
    if (tableData.length > 0) {
      sortTableData(Object.keys(lastSort)[0], true)
    }
  }, [tableData])

  useEffect(() => {
    const timer = setInterval(() => {
      fetchPortfolio(selectedInterval, selectedCategory)
    }, 60000)

    return () => clearInterval(timer)
  }, [_user, selectedInterval, selectedCategory])

  useEffect(() => {
    fetchPortfolio(selectedInterval, selectedCategory)
  }, [_user])

  const onClickCollection = (collection: Collection) => {
    navigate(`/projects/${collection.slug}`)
  }

  const onClickAssetCategory = (key: string) => {
    if (key === 'nfts') {
      setTableData(portfolio.collections)
    } else if (key === 'tokens') {
      setTableData(portfolio.tokens)
    } else if (key === 'combined') {
      setTableData(portfolio.tokens.concat(portfolio.collections))
    }

    setSelectedCategory(key)
  }

  const updateFiatMode = (checked: boolean) => {
    setFiatModeChecked(checked)
    localStorage.setItem('fiatMode', JSON.stringify(checked))
  }

  const updateHideBalances = (checked: boolean) => {
    setHideBalancesChecked(checked)
    localStorage.setItem('hideBalances', JSON.stringify(checked))
  }

  const sortTableData = (key: string, keep = false) => {
    let result = []
    let direction = ''
    const tableDataCopy = [...tableData]

    if (keep) {
      if (key === 'name') {
        if (lastSort[key] === 'inverted') {
          result = tableDataCopy.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,
          )
        } else {
          result = tableDataCopy.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1,
          )
        }
      } else {
        if (lastSort[key] === 'inverted') {
          result = tableDataCopy.sort((a, b) => {
            const valueA = a[key] as number
            const valueB = b[key] as number
            return valueA - valueB
          })
        } else {
          result = tableDataCopy.sort((a, b) => {
            const valueB = b[key] as number
            const valueA = a[key] as number
            return valueB - valueA
          })
        }
      }
    } else {
      if (key === 'name') {
        if (lastSort[key] === 'normal') {
          result = tableDataCopy.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1,
          )
          direction = 'inverted'
        } else {
          result = tableDataCopy.sort((a, b) =>
            b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1,
          )
          direction = 'normal'
        }
      } else {
        if (lastSort[key] === 'normal') {
          result = tableDataCopy.sort((a, b) => {
            const valueA = a[key] as number
            const valueB = b[key] as number
            return valueA - valueB
          })
          direction = 'inverted'
        } else {
          result = tableDataCopy.sort((a, b) => {
            const valueB = b[key] as number
            const valueA = a[key] as number
            return valueB - valueA
          })
          direction = 'normal'
        }
      }
    }

    if (direction) {
      const newLastSort = {} as SortEntity
      newLastSort[key] = direction
      setLastSort(newLastSort)
    }

    setTableDataSorted(result)
  }

  const renderTableHeader = (displayName: string, key: string) => {
    return (
      <div className="flex flex-row gap-1">
        <p className="self-center">{displayName}</p>
        {lastSort[key] === 'inverted' && (
          <IoCaretUp className="self-center text-white" />
        )}

        {lastSort[key] === 'normal' && (
          <IoCaretDown className="self-center text-white" />
        )}
      </div>
    )
  }

  const onIntervalClicked = async (interval: string) => {
    if (historyLoading) {
      return
    }

    setSelectedInterval(interval)
    setHistoryLoading(true)
    await fetchPortfolio(interval, selectedCategory)
    setHistoryLoading(false)
  }

  const historyData = (
    key: string,
  ): { id: string; data: { x: string; y: number }[] }[] => {
    if (_user.pro) {
      if (portfolio.history) {
        if (fiatModeChecked) {
          return [
            {
              id: 'USD Value',
              data: portfolio.history.map((element) => {
                return {
                  x: element.key,
                  y: element[`${key}_usd`] as number,
                }
              }),
            },
          ]
        } else {
          return [
            {
              id: 'ADA Value',
              data: portfolio.history.map((element) => {
                return {
                  x: element.key,
                  y: element[key] as number,
                }
              }),
            },
          ]
        }
      } else {
        return []
      }
    } else {
      return [
        {
          id: 'ADA Value',
          data: FREE_USER_HISTORY.map((element) => {
            return {
              x: element.key,
              y: element[key] as number,
            }
          }),
        },
      ]
    }
  }

  const onMouseLeaveLineChart = () => {
    setValueFromChart(null)
    setDateFromChart(null)
  }

  const onMouseMoveLineChart = (point: { x: string; y: number }) => {
    setValueFromChart(point.y)
    setDateFromChart(point.x)
  }

  const onClickTotalInWallet = (totalData: TotalsInWalletEntity) => {
    if (totalData.key === selectedSeries.key) {
      setSelectedSeries(TOTALS_IN_WALLET[0])
      onClickAssetCategory('combined')
    } else {
      setSelectedSeries(totalData)
      onClickAssetCategory(totalData.assetCategory)
    }
  }

  const formattedChangeValue = () => {
    const change = changeValueForCurrency()
    const symbol = fiatModeChecked ? USD_SYMBOL : ADA_SYMBOL
    const sign = isPositiveDiff(change) ? '+' : '-'

    return `${sign}${symbol}${formatNumber(change, 0, true)}`
  }

  const formattedChangePercentage = () => {
    const changeKey = fiatModeChecked
      ? selectedSeries.keyChangePercentageUsd
      : selectedSeries.keyChangePercentage
    const changePercentage = portfolio?.change[changeKey]

    return `${intToString(changePercentage, true)}%`
  }

  const changeValueForCurrency = () => {
    const changeKey = fiatModeChecked
      ? selectedSeries.keyChangeUsd
      : selectedSeries.keyChange
    const change = portfolio?.change[changeKey]

    return change
  }

  const formattedChangeValueFromChart = () => {
    const value = valueFromChart
    const change =
      (value as number) - historyData(selectedSeries.key)[0].data[0].y
    const symbol = fiatModeChecked ? USD_SYMBOL : ADA_SYMBOL
    const sign = isPositiveDiff(change) ? '+' : '-'

    return `${sign}${symbol}${formatNumber(change, 0, true)}`
  }

  const formattedChangePercentageFromChart = () => {
    const value = valueFromChart
    const changePercentage = percentageDiff(
      historyData(selectedSeries.key)[0].data[0].y,
      value as number,
    )

    return `${intToString(changePercentage, true)}%`
  }

  const changeValueFromChart = () => {
    const value = valueFromChart
    return percentageDiff(
      historyData(selectedSeries.key)[0].data[0].y as number,
      value as number,
    )
  }

  const formatSmartContractValue = (totalData: {
    keyUsd: string
    key: string
  }) => {
    const key = fiatModeChecked ? totalData.keyUsd : totalData.key
    const symbol = fiatModeChecked ? USD_SYMBOL : `${ADA_SYMBOL} `

    return `${symbol}${intToString(portfolio[key] as number, true)}`
  }

  const formattedWalletTotalsChange = (totalData: {
    keyChangeUsd: string
    keyChange: string
    keyChangePercentageUsd: string
    keyChangePercentage: string
  }) => {
    const changeKey = fiatModeChecked
      ? totalData.keyChangeUsd
      : totalData.keyChange
    const changeKeyPercentage = fiatModeChecked
      ? totalData.keyChangePercentageUsd
      : totalData.keyChangePercentage
    const symbol = fiatModeChecked ? USD_SYMBOL : ADA_SYMBOL
    const change = portfolio?.change[changeKey]
    const sign = isPositiveDiff(change) ? '+' : '-'
    const changePercentage = portfolio?.change[changeKeyPercentage]

    return (
      <p>
        <span className={`${hideBalancesChecked && 'blur'}`}>
          {sign}
          {symbol}
          {formatNumber(change, 0, true)}
        </span>{' '}
        ({intToString(changePercentage, true)}%)
      </p>
    )
  }

  const formattedSmartContractCount = (totalData: TotalDataEntity) => {
    const key = fiatModeChecked ? totalData.countKeyUsd : totalData.countKey
    const symbol = fiatModeChecked ? USD_SYMBOL : `${ADA_SYMBOL} `
    const text = totalData.countText

    if (totalData.countFormat) {
      return `${symbol}${totalData.countFormat(
        portfolio[key] as number,
      )} ${text}`
    } else {
      return `${portfolio[totalData.countKey] as number} ${text}`
    }
  }

  const yScaleMinMax = () => {
    const history = historyData(selectedSeries.key)
    const minObject = history[0].data.reduce(function (prev, current) {
      return prev.y < current.y ? prev : current
    })
    const maxObject = history[0].data.reduce(function (prev, current) {
      return prev.y > current.y ? prev : current
    })

    const minValue = minObject.y
    const maxValue = maxObject.y

    return { min: minValue, max: maxValue }
  }

  if (isLoading) {
    return (
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">Portfolio</p>

          <div className="flex flex-row gap-2 mt-12 text-xl font-bold text-neutral-400 animate-pulse">
            <BounceLoader
              className="self-center"
              color={'#ffffff'}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <p className="self-center">Loading</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="relative flex justify-center w-full h-full min-h-screen bg-neutral-900">
        <div className="flex flex-col w-full p-10 pt-32 pb-20 2xl:w-5/6">
          <p className="text-6xl font-bold text-neutral-200">Portfolio</p>

          <div className="flex flex-row gap-6 mt-6">
            <div className="flex flex-row gap-2">
              <label className="relative inline-block h-6 rounded-full w-11">
                <input
                  checked={fiatModeChecked}
                  type="checkbox"
                  className="w-0 h-0 opacity-0 peer"
                  onChange={(e) => updateFiatMode(e.target.checked)}
                />
                <span
                  className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 rounded-full duration-300 before:content-[''] before:absolute before:w-4 before:h-4 before:bottom-1 before:left-1 before:rounded-full
                before:bg-white before:duration-300 peer-checked:before:translate-x-5 peer-checked:bg-blue-500"></span>
              </label>
              <p className="self-center text-sm font-bold text-neutral-200">
                USD Mode{' '}
                {adaUsd && (
                  <span className="font-semibold text-blue-400">
                    ({USD_SYMBOL}
                    {formatNumber(adaUsd, 3, true)})
                  </span>
                )}
              </p>
            </div>

            <div className="flex flex-row gap-2">
              <label className="relative inline-block h-6 rounded-full w-11">
                <input
                  checked={hideBalancesChecked}
                  type="checkbox"
                  className="w-0 h-0 opacity-0 peer"
                  onChange={(e) => updateHideBalances(e.target.checked)}
                />
                <span
                  className="absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-300 rounded-full duration-300 before:content-[''] before:absolute before:w-4 before:h-4 before:bottom-1 before:left-1 before:rounded-full
                before:bg-white before:duration-300 peer-checked:before:translate-x-5 peer-checked:bg-blue-500"></span>
              </label>
              <p className="self-center text-sm font-bold text-neutral-200">
                Hide Balances{' '}
              </p>
            </div>
          </div>

          <div className="flex flex-col w-full gap-10 mt-4 lg:flex-row">
            <div className="relative flex flex-col px-6 pt-6 pb-3 border rounded-lg lg:w-1/2 2xl:w-1/2 border-neutral-800 bg-stone-900">
              {_user.pro === false && (
                <div className="absolute top-0 left-0 z-10 w-full h-full bg-black rounded-lg bg-opacity-80 hover:bg-opacity-0">
                  <div className="flex flex-col justify-center w-full h-full gap-2">
                    <div className="flex flex-row self-center gap-2">
                      <p className="self-center font-semibold text-neutral-200">
                        Portfolio History requires Poki
                      </p>
                      <p className="self-center px-2 py-1 text-xs font-bold border rounded-lg border-neutral-800 text-neutral-200">
                        PRO
                      </p>
                    </div>
                    <button
                      onClick={() => navigate(RoutePaths.LifetimePass)}
                      className="self-center px-10 py-2 mt-2 text-sm font-bold text-center text-white bg-blue-600 rounded-lg hover:bg-blue-500">
                      Unlock Poki Pro
                    </button>
                  </div>
                </div>
              )}

              <div className="flex flex-row justify-between">
                <div className="relative flex flex-col">
                  <div className="flex flex-row gap-1">
                    <div className="flex flex-row gap-1">
                      {selectedSeries.key !== 'value_total' && (
                        <div className="flex flex-row gap-1">
                          <p
                            className="self-center font-bold cursor-pointer text-neutral-400"
                            onClick={() =>
                              setSelectedSeries(TOTALS_IN_WALLET[0])
                            }>
                            Total
                          </p>
                          <p className="self-center font-bold text-neutral-400">
                            /
                          </p>
                        </div>
                      )}
                      <p
                        className={`self-center font-bold
                          ${
                            selectedSeries.key === 'value_total'
                              ? 'text-neutral-400'
                              : 'text-neutral-200'
                          }`}>
                        {selectedSeries.name}
                      </p>
                    </div>
                    {selectedSeries.key === 'value_total' && (
                      <div className="relative self-center">
                        <IoInformationCircleOutline
                          size={19}
                          color="#a3a3a3"
                          className="self-center cursor-pointer"
                          onMouseEnter={() => setShowValueTooltip(true)}
                          onMouseLeave={() => setShowValueTooltip(false)}
                        />
                        {showValueTooltip && (
                          <div className="absolute p-3 bg-gray-300 rounded-lg w-52 bottom-1 left-5">
                            <p className="text-xs font-semibold">
                              This value includes your Smart Contract (SC)
                              Holdings. However, those SC holdings are not
                              included in the history graphs below.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {fiatModeChecked ? (
                    <p
                      className={`text-3xl font-bold text-neutral-200 ${
                        hideBalancesChecked && 'blur'
                      }`}>
                      <span className="font-normal">$</span>
                      {valueFromChart !== null
                        ? intToString(valueFromChart as number, true)
                        : intToString(
                            portfolio[selectedSeries.keyUsd] as number,
                            true,
                          )}{' '}
                    </p>
                  ) : (
                    <p
                      className={`text-3xl font-bold text-neutral-200 ${
                        hideBalancesChecked && 'blur'
                      }`}>
                      <span className="font-normal">{ADA_SYMBOL} </span>
                      {valueFromChart !== null
                        ? intToString(valueFromChart as number, true)
                        : intToString(
                            portfolio[selectedSeries.key] as number,
                            true,
                          )}{' '}
                    </p>
                  )}

                  <p className="text-sm font-semibold text-neutral-400">
                    {dateFromChart !== null
                      ? formatDate(dateFromChart, selectedInterval)
                      : formatDate(new Date().toString(), selectedInterval)}
                  </p>
                  {valueFromChart !== null ? (
                    <div
                      className={`font-bold text-xs ${numberColor(
                        changeValueFromChart(),
                      )}`}>
                      <span className={`${hideBalancesChecked && 'blur'}`}>
                        {formattedChangeValueFromChart()}
                      </span>{' '}
                      ({formattedChangePercentageFromChart()})
                    </div>
                  ) : (
                    <div
                      className={`font-bold text-xs ${numberColor(
                        changeValueForCurrency(),
                      )}`}>
                      <span className={`${hideBalancesChecked && 'blur'}`}>
                        {formattedChangeValue()}
                      </span>{' '}
                      ({formattedChangePercentage()})
                    </div>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="flex flex-row gap-2">
                    {INTERVALS.map((intervalData, index) => {
                      return (
                        <p
                          key={`total-interval-${index}`}
                          className={`px-3 py-1 text-xs font-semibold
                              bg-gray-700 rounded-lg cursor-pointer
                              hover:bg-gray-500 text-neutral-200
                              ${
                                intervalData.key === selectedInterval
                                  ? 'bg-gray-500'
                                  : ''
                              }`}
                          onClick={() => onIntervalClicked(intervalData.key)}>
                          {intervalData.display}
                        </p>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className="h-20 mt-12 lg:h-36">
                {historyLoading ? (
                  <div className="flex flex-row justify-center gap-2">
                    <BounceLoader
                      className="self-center"
                      color={'#ffffff'}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />

                    <p className="self-center text-lg font-bold text-neutral-400">
                      Loading
                    </p>
                  </div>
                ) : (
                  <LineChart
                    data={historyData(selectedSeries.key)[0].data}
                    onMouseLeave={onMouseLeaveLineChart}
                    onMouseMove={onMouseMoveLineChart}
                    yScaleMinMax={yScaleMinMax()}
                  />
                )}
              </div>

              <div className="flex flex-row justify-between mt-3">
                <p className="text-xs text-neutral-500">
                  © Poki | Mobile Cardano Portfolio Tracker
                </p>
                <p className="text-xs text-neutral-500">www.getpoki.com</p>
              </div>
            </div>

            <div className="flex flex-col justify-between w-full gap-4 lg:w-1/2">
              <div className="flex flex-col">
                <p className="text-lg font-bold tracking-wide text-neutral-200">
                  Wallet Holdings
                </p>
                <div className="grid w-full grid-cols-3 gap-8 mt-4">
                  {TOTALS_IN_WALLET.filter(
                    (total) => total.display === true,
                  ).map((totalData, index) => {
                    return (
                      <div className="flex flex-col" key={`totals-${index}`}>
                        <div
                          className={`relative flex flex-col p-4 border rounded-lg bg-stone-900
                          cursor-pointer border-neutral-800 hover:border-neutral-600
                          ${
                            selectedSeries.key === totalData.key
                              ? 'border-neutral-600'
                              : ''
                          }
                          `}
                          onClick={() => onClickTotalInWallet(totalData)}>
                          <p className="text-sm font-bold text-neutral-400">
                            {totalData.name}
                          </p>
                          {_user.pro === false &&
                            totalData.proRequired === true && (
                              <p className="absolute px-2 py-1 text-xs font-bold text-white border rounded-lg right-2 top-2 border-neutral-800">
                                PRO
                              </p>
                            )}
                          {fiatModeChecked ? (
                            <p
                              className={`text-lg font-bold text-neutral-200 ${
                                hideBalancesChecked && 'blur'
                              }`}>
                              <span className="font-normal">{USD_SYMBOL}</span>
                              {intToString(
                                portfolio[totalData.keyUsd] as number,
                                true,
                              )}{' '}
                            </p>
                          ) : (
                            <p
                              className={`text-lg font-bold text-neutral-200 ${
                                hideBalancesChecked && 'blur'
                              }`}>
                              <span className="font-normal">{ADA_SYMBOL} </span>
                              {intToString(
                                portfolio[totalData.key] as number,
                                true,
                              )}{' '}
                            </p>
                          )}
                          {_user.pro === false &&
                          totalData.proRequired === true ? (
                            <p className="text-xs font-bold text-neutral-200">
                              Pro required
                            </p>
                          ) : (
                            <div
                              className={`font-bold text-xs ${
                                fiatModeChecked
                                  ? numberColor(
                                      portfolio?.change[totalData.keyChangeUsd],
                                    )
                                  : numberColor(
                                      portfolio?.change[totalData.keyChange],
                                    )
                              }`}>
                              {formattedWalletTotalsChange(totalData)}
                            </div>
                          )}
                        </div>
                        {totalData.key === 'value_nfts' && (
                          <div className="flex self-center px-4 pt-3 pb-1 -mt-2 bg-blue-600 rounded-lg">
                            <p className="text-xs font-semibold text-neutral-200">
                              Instant Sell:{' '}
                              <span
                                className={`${hideBalancesChecked && 'blur'}`}>
                                {fiatModeChecked
                                  ? USD_SYMBOL
                                  : ADA_SYMBOL + ' '}
                                {fiatModeChecked
                                  ? intToString(
                                      portfolio?.value_nfts_instant_usd,
                                    )
                                  : intToString(portfolio?.value_nfts_instant)}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="flex flex-col">
                <p className="text-lg font-bold tracking-wide text-neutral-200">
                  Smart Contract Holdings
                </p>
                <div className="grid w-full grid-cols-3 gap-8 mt-4">
                  {TOTALS_IN_SMART_CONTRACTS.map((totalData, index) => {
                    return (
                      <div
                        className={`relative flex flex-col p-4 border rounded-lg border-neutral-800 bg-stone-900 ${
                          totalData.comingSoon ? 'opacity-50' : 'opacity-100'
                        }`}
                        key={`totals-${index}`}>
                        <p className="text-sm font-bold text-neutral-400">
                          {totalData.name}
                        </p>
                        {_user.pro === false && (
                          <p className="absolute px-2 py-1 text-xs font-bold text-white border rounded-lg right-2 top-2 border-neutral-800">
                            PRO
                          </p>
                        )}
                        {totalData.comingSoon ? (
                          <div className="flex flex-col">
                            <p className="text-lg font-bold text-neutral-200">
                              <span className="font-normal">{ADA_SYMBOL} </span>
                              0
                            </p>
                            <p className="text-xs font-bold text-neutral-200">
                              Coming Soon
                            </p>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <p
                              className={`text-lg font-bold text-neutral-200 ${
                                hideBalancesChecked && 'blur'
                              }`}>
                              {formatSmartContractValue(totalData)}
                            </p>
                            {_user.pro === false ? (
                              <p className="text-xs font-bold text-neutral-200">
                                Pro required
                              </p>
                            ) : (
                              <p className="text-xs font-bold text-neutral-200">
                                {formattedSmartContractCount(totalData)}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <p className="mt-12 text-2xl font-bold tracking-wide text-white">
            Your Assets
          </p>

          <div className="flex flex-row gap-4">
            {ASSET_CATEGORIES.map((categoryData, index) => {
              return (
                <div
                  className="flex flex-row gap-4"
                  key={`asset-category-${index}`}>
                  <p
                    className={`self-center mt-4 font-bold cursor-pointer
                   ${
                     categoryData.key === selectedCategory
                       ? 'text-neutral-200'
                       : 'text-neutral-400'
                   }`}
                    onClick={() => onClickAssetCategory(categoryData.key)}>
                    {categoryData.name}
                  </p>
                  {index < ASSET_CATEGORIES.length - 1 && (
                    <p className="self-center mt-4 font-bold text-neutral-200">
                      |
                    </p>
                  )}
                </div>
              )
            })}
          </div>

          <div className="flex self-center justify-center w-full mt-8 overflow-auto">
            <table className="w-full overflow-auto text-xs font-bold text-left table-auto">
              <thead className="text-neutral-200 bg-neutral-800">
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('name')}>
                  {renderTableHeader('Name', 'name')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('price')}>
                  {renderTableHeader(`Price`, 'price')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('change_percentage')}>
                  {renderTableHeader(
                    `${selectedInterval} %`,
                    'change_percentage',
                  )}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('count')}>
                  {renderTableHeader('Asset Count', 'count')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('value')}>
                  {renderTableHeader(`Value`, 'value')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('share_category')}>
                  {renderTableHeader('Category Share', 'share_category')}
                </th>
                <th
                  className="px-6 py-3 hover:cursor-pointer"
                  onClick={() => sortTableData('collection_offer')}>
                  {renderTableHeader(`Collection Offer`, 'collection_offer')}
                </th>
              </thead>
              <tbody>
                {tableDataSorted.map((collection, index) => {
                  return (
                    <tr
                      className="text-xs border-b text-neutral-200 border-neutral-800"
                      key={`collection-${index}`}>
                      <td
                        className="flex flex-row gap-2 px-6 py-3 cursor-pointer"
                        onClick={() => onClickCollection(collection)}>
                        {collection.image ? (
                          <LazyLoadImage
                            src={collection.image}
                            className="self-center w-12"
                          />
                        ) : (
                          <div className="flex self-center justify-center w-10 h-10 rounded-full bg-neutral-800">
                            <p className="self-center text-xl font-normal">?</p>
                          </div>
                        )}
                        <div className="flex flex-col w-full gap-1">
                          <p className="font-bold">{collection.name}</p>
                          <p className="font-normal text-neutral-400 font-xs">
                            {collection.share}%
                          </p>
                          <div className="self-start w-32 bg-neutral-700">
                            <div
                              className="w-full h-2 bg-neutral-200"
                              style={{ width: `${collection.share}%` }}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-3">
                        {ADA_SYMBOL} {intToString(collection.price)}
                      </td>
                      <td
                        className={`${numberColor(
                          collection.change_percentage,
                        )} px-6 py-3`}>
                        {numberWithSign(collection.change_percentage, 2)}%
                      </td>
                      <td
                        className={`px-6 py-3 ${
                          hideBalancesChecked && 'blur'
                        }`}>
                        {intToString(collection.count)}
                      </td>
                      <td
                        className={`px-6 py-3 ${
                          hideBalancesChecked && 'blur'
                        }`}>
                        {ADA_SYMBOL} {intToString(collection.value)}
                      </td>
                      <td className="px-6 py-3">
                        {collection.share_category}%
                      </td>
                      <td className="px-6 py-3">
                        <a
                          className={`py-2 flex justify-center text-center ${
                            collection.collection_offer !== 'N/A'
                              ? 'bg-blue-600 cursor-pointer hover:bg-blue-500'
                              : 'cursor-default'
                          } rounded-lg`}
                          href={`https://jpg.store/collection/${collection.jpg_store_name}`}
                          target="_blank"
                          rel="noreferrer">
                          {collection.collection_offer !== 'N/A' && ADA_SYMBOL}{' '}
                          {collection.collection_offer}
                          <span className="ml-1 font-normal">
                            {collection.collection_offer !== 'N/A' &&
                              `(${percentageDiff(
                                collection.price,
                                collection.collection_offer as number,
                              ).toFixed()}%)`}
                          </span>
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
